import { Spin } from 'antd'
import React from 'react'

const IndicateLoader = ({ size }) => { 
  return (
    <div>
        <Spin size={ size ? size : 'small' } />
    </div>
  )
}

export default IndicateLoader
