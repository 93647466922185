import React from "react";
import { Button, Col, Divider, Form, Input, message, Row, Typography, Upload } from "antd";
import Loader from "../../loader/Loader";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteDocumentMutation } from "../../../features/api/adminApi/employee_api";
import { setVendorDocManually } from "../../../features/slice/documentSlice";
import errorMessage from "../../../global/errorMessage/ErrorMessage";
import { CheckOutlined } from "@ant-design/icons";
import { _handleEditNavigation, beforeUpload } from "../../../global/helpers";

const VendorAddtionInformation = ({
	vendorFetching,
	vendorLoading,
	isSucess,
	viewEmployee,
	handleVendorSave,
	setVendorAddDocs,
	vendorAddDocs,
	vendorId,
	setDeleteShow,
	setRemoveDoc,
	navigate,
}) => {
	//Hooks
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	//states
	const vendorDocLoading = useSelector((state) => state.document.vendorDocLoading);
	const vendorAddDoc = useSelector((state) => state.document.vendorBankDoc);

	//Api

	const [deleteDocument, { isLoading: deleteLoading }] = useDeleteDocumentMutation();

	//function
	const handleDeleteDocument = (file) => {
		if (file.id) {
			deleteDocument(file.id)
				.unwrap()
				.then((res) => {
					dispatch(
						setVendorDocManually({ id: file.id, type: "delete", name: "vendorAddDoc" })
					);
					message.success("Document deleted successfully");
				})
				.catch((err) => errorMessage(err?.data?.error));
		}
	};

	const handleChange = (info) => {
		const id = info.file.uid;
		if (info.file.status === "done") {
			setVendorAddDocs((state) => [...state, info?.file]);
			dispatch(setVendorDocManually({ file: info.file, type: "docs", name: "vendorAddDoc" }));
		} else if (info.file.status === "removed") {
			setVendorAddDocs((state) => {
				return state?.filter((item) => item.uid !== id);
			});
		}
	};

	return (
		<>
			{vendorLoading === true ||
			vendorFetching === true ||
			deleteLoading === true ||
			vendorDocLoading === true ? (
				<Loader />
			) : (
				isSucess && (
					<>
						<Form
							form={form}
							initialValues={{
								notes: viewEmployee?.notes,
								alert: viewEmployee?.alert,
							}}
							wrapperCol={{
								span: 8,
							}}
							labelWrap
							labelAlign="left"
							labelCol={{
								span: 4,
							}}
							// initialValues={handleInitialValues(vendor)}
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								// gap: "22px",
								paddingTop: "20px",
							}}
							onFinish={handleVendorSave}>
							<Row gutter={[10, 10]}>
								<Col span={24}>
									<Typography.Title level={5}>
										Additional Information
									</Typography.Title>
									{/* <Row justify={"space-between"}>
										<Col span={12}>
											<Typography.Title level={5}>
												Additional Information
											</Typography.Title>
										</Col>
										<Col
											span={12}
											style={{
												textAlign: "end",
											}}>
											<Button
												type="primary"
												onClick={() =>
													_handleEditNavigation(
														navigate,
														`/admin/vendors/edit/${vendorId}`
													)
												}>
												Edit
											</Button>
										</Col>
									</Row> */}
									<Divider />
								</Col>
								<Col span={24}>
									<Form.Item name={"notes"} label="Notes">
										<Input.TextArea placeholder="Notes" />
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item name={"alert"} label="Alert">
										<Input.TextArea placeholder="Alert" />
									</Form.Item>
								</Col>
								<Col
									span={24}
									// className={vendorAddDocs?.length < 1 ? "event-none" : ""}
								>
									<Form.Item name="additional_document" label="Documents">
										<Upload
											beforeUpload={beforeUpload}
											listType="text"
											customRequest={async (options) => {
												const { onSuccess, file } = options;
												setVendorAddDocs((state) => {
													return [...state, file];
												});
												onSuccess("ok");
											}}
											multiple={true}
											action="https://jsonplaceholder.typicode.com"
											accept=".pdf,.png,.jpg,.xls"
											method="GET"
											fileList={vendorAddDocs}
											onRemove={(file) => {
												file.path = "addtional_document";
												setRemoveDoc(file);
												setDeleteShow(true);
												return false;
											}}
											onChange={(info) =>
												handleChange(info, "addtional_document")
											}>
											<div className="upload">
												<Button icon={<UploadOutlined />}>
													Upload Document
												</Button>
												<Button
													icon={<PlusOutlined />}
													className="inneraddbuttonInput"
												/>
											</div>
										</Upload>
									</Form.Item>
								</Col>
								<Col className="employee_view_btn">
									{/* <Button className="cancelBtn">Cancel</Button> */}
									<Button
										loading={vendorLoading}
										type="primary"
										htmlType="submit"
										className="saveButtonPrimary"
										icon={<CheckOutlined />}>
										Save
									</Button>
								</Col>
							</Row>
						</Form>
					</>
				)
			)}
		</>
	);
};
export default VendorAddtionInformation;
