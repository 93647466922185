import { mainApi } from "../mainApi";

//Auth Api
export const masterApi = mainApi.injectEndpoints({
	endpoints: (builder) => ({
		// //new
		// addEditVendor: builder.mutation({
		// 	query: ({ id, formData, key = 0 }) => {
		// 		return {
		// 			url: id ? `company/vendor/${id}/${key}` : `company/vendor`,
		// 			method: "POST",
		// 			body: formData,
		// 		};
		// 	},
		// 	invalidatesTags: ["Vendor"],
		// }),

		// //list vendor
		// listVendor: builder.query({
		// 	query: (params) =>
		// 		`company/vendor?${params}`,
		// 	providesTags: ["Vendor"],
		// }),

		// //View vendor
		// viewVendor: builder.query({
		// 	query: (vendorId) => `company/vendor/${vendorId}`,
		// 	providesTags: ["Vendor"],
		// }),

		//Add vendor
		addMasterName: builder.mutation({
			query: ({formData, newMasterType}) => ({
				url: `company/${newMasterType}`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Master"],
		}),

		// //Edit vendor
		// editVendor: builder.mutation({
		// 	query: ({ id, formData, key }) => {
		// 		return {
		// 			url: `company/vendor/${id}/${key}`,
		// 			method: "POST",
		// 			body: formData,
		// 		};
		// 	},
		// 	invalidatesTags: ["Vendor"],
		// }),

        //Edit Master
        editMasterName: builder.mutation({
			query: ({ id, formData, newMasterType}) => {
				return {
					url: `company/${newMasterType}/${id}`,
					method: "POST",
					body: formData,
				};
			},
			invalidatesTags: ["Master"],
		}),

		// //Vendor employee
		// listVendorEmployee: builder.query({
		// 	query: (id) => `company/vendor/employee/${id}`,
		// }),

		//deleteVendor
		deleteMasterName: builder.mutation({
			query: ({id, newMasterType}) => ({
				url: `company/${newMasterType}/${id}`,
				method: "PATCH",
			}),
			invalidatesTags: ["Master"],
		}),
		// deleteVendorDocument: builder.mutation({
		// 	query: ({ id, path }) => ({
		// 		url: `company/${path}/${id}`,
		// 		method: "DELETE",
		// 	}),
		// 	invalidatesTags: ["Vendor"],
		// }),

		// //status Change
		// vendorStatusChange: builder.mutation({
		// 	query: ({ id, formData }) => ({
		// 		url: `/company/vendor-status/${id}`,
		// 		method: "PATCH",
		// 		body: formData,
		// 	}),
		// 	invalidatesTags: ["Vendor"],
		// }),
		// //Essential
		// essential: builder.query({
		// 	query: (list) => `company/essential-list?select=${list}`,
		// 	providesTags: ["Essential"],
		// }),
		// //updateAndDeleteLogo
		// vendorLogoUpdateAndDelete: builder.mutation({
		// 	query: ({ id, type, formData }) => {
		// 		return {
		// 			url: `company/vendor/logo/${type}/${id}`,
		// 			method: "POST",
		// 			body: formData,
		// 		};
		// 	},
		// 	invalidatesTags: ["Vendor"],
		// }),
	}),
});

export const {
	// useAddEditVendorMutation,
	// useListVendorQuery,
	// useViewVendorQuery,
	// useAddVendorMutation,
	useEditVendorMutation,
	useDeleteVendorMutation,
	// useListVendorEmployeeQuery,
	// useVendorStatusChangeMutation,
	// useDeleteVendorDocumentMutation,
	// useEssentialQuery,
    // useVendorLogoUpdateAndDeleteMutation
    useEditMasterNameMutation,
    useAddMasterNameMutation,
    useDeleteMasterNameMutation

} = masterApi;
