import React, { useState, useEffect } from "react";
import { Typography, Col, Row, Form, Button, Modal, message, Spin } from "antd";
import ResultComponent from "../check_id_new/ResultComponent";
import KeyPadComponent from "../check_id_new/KeyPadComponent";
import "../check_id_new/style.css";
import {
	useEmployeeCheckInOutApiMutation,
	useEmployeeFindApiMutation,
	useServerTimeQuery,
} from "../../features/api/check_in_out";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import errorMessage from "../../global/errorMessage/ErrorMessage";
import circleInsideLogo from "../../assets/logo-clockin.PNG";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastBoxMessage, ToastMessage, Utctime } from "../../global/helpers";
import Loader from "../loader/Loader";
import Clock from "react-live-clock";
import "react-notifications-component/dist/theme.css";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

let count = 0;
const CheckIn = (props) => {
	const { time } = props;
	const { data: serverTime, isLoading: serverTimeLoading, refetch } = useServerTimeQuery();
	const times = useSelector((state) => state.time.time);
	const { companyId } = useParams();
	const [result, setResult] = useState("");
	const [form] = Form.useForm();
	const [actualTime, setActualTime] = useState(dayjs().utc().tz("America/New_York"));
	const [employee, setEmployee] = useState({
		check_in_id: "",
		employee_id: "",
		check_in_status: "",
	});
	const [recordTime, setRecordTime] = useState("");
	const [id, setId] = useState();
	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
	};

	//Api
	const [employeeFind, { isError: findIsError, error: findError, isLoading: findLoading }] =
		useEmployeeFindApiMutation();
	const [checkInId, { isLoading }] = useEmployeeCheckInOutApiMutation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [toasterBox, setToasterBox] = useState(false);
	const [toasterMsg, setToasterMsg] = useState({});
	const [toasterBgcolor, setToasterBgcolor] = useState("");

	const onClick = (button) => {
		if (button === "CE") {
			backspace();
		} else {
			setResult(result + button);
		}
	};

	const backspace = () => {
		setResult(result.slice(0, -1));
	};
	const submitFn = () => {
		// const date = Utctime(actualTime)
		const date = dayjs(actualTime);
		const formData = {
			check_in_id: result,
			company_id: "all-time-greek",
			actual_time: dayjs(date).format("hh:mm"),
			meridiem: dayjs(date).format("A"),
		};
		employeeFind(formData)
			.unwrap()
			.then((res) => {
				setEmployee(res);
				setResult("");
				setRecordTime(res.record_time);
				setIsModalOpen(true);
			})
			.catch((err) => {
				setResult("");
				const error = ToastBoxMessage("", "error", err?.data?.error);
				setToasterMsg({type: "error", message: error})
				setToasterBgcolor("#e74c3c")
				setToasterBox(true)
				setTimeout(() => {
					setToasterBox(false); 
				  }, 3000);
			});
	};
	const api = () =>
		fetch("https://worldtimeapi.org/api/ip")
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (res.datetime) {
					setActualTime(dayjs(res?.datetime).utc().tz("America/New_York"));
				} else {
					setActualTime(dayjs().utc().tz("America/New_York"));
				}
			});

	const handleClick = (values) => {
		let formData = {};
		// const date = Utctime(actualTime)
		const date = dayjs(actualTime);
		if (values?.check_in_status === false) {
			formData.status = 1;
			formData.company_id = employee.company_id;
			formData.employee_id = values?.employee_id;
			formData.actual_time_in = `${dayjs(date).format("hh:mm")}`;
			formData.in_meridiem = dayjs(date).format("A");
			formData.check_in_id = employee.check_in_id;
		} else {
			formData.employee_id = values?.employee_id;
			formData.company_id = employee.company_id;
			formData.status = 0;
			formData.actual_time_out = `${dayjs(date).format("hh:mm")}`;
			formData.out_meridiem = dayjs(date).format("A");
			formData.check_in_id = employee.check_in_id;
		}
		checkInId(formData)
			.unwrap()
			.then((res) => {
				const success = ToastBoxMessage(res?.message, "success");
				setToasterMsg({type: "success", message: success})
				setToasterBgcolor("#07bc0c")
				setToasterBox(true)
				setTimeout(() => {
					setToasterBox(false); 
				  }, 3000);
				setEmployee({});
				setResult("");
				setIsModalOpen(false);
			})
			.catch((err) => {
				setResult("");
				const error = ToastBoxMessage("", "error", err?.data?.error);
				setToasterMsg({type: "error", message: error})
				setToasterBgcolor("#e74c3c")
				setToasterBox(true)
				setTimeout(() => {
					setToasterBox(false); 
				  }, 3000);

			});
			
				// errorMessage(err?.data?.error)
	};

	const onChangeInput = (e) => {
		setResult(e.target.value);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const handleToasterOk = () => {
		setToasterBox(false);
	};

	const handleToasterCancel = () => {
		setToasterBox(false);
	};

	useEffect(() => {
		window.addEventListener("resize", setDimension);
		return () => {
			window.removeEventListener("resize", setDimension);
		};
	}, [screenSize]);

	useEffect(() => {
		if (serverTime) {
			setActualTime(dayjs(serverTime?.data, "YYYY-MM-DD hh:mm:ss A"));
		}
	}, [screenSize]);

	return (
		<div style={{ background: "#DDE4EE", height: "100vh" }}>
			{
				// loading === true ||
				// !actualTime ||
				// serverTimeLoading === true ||
				serverTimeLoading === true ? (
					<Loader loading={serverTimeLoading} />
				) : (
					<Row type="flex" align="middle" justify={"center"} style={{ height: "100vh" }}>
						<Col
							className="check-in-container"
							style={{
								width: screenSize.dynamicWidth >= 700 ? "60%" : "90%",
								maxWidth: "800px",
								margin: "0 auto",
							}}>
							<Row
								className="check-in-wrapper"
								style={{
									background: "#29303a",
									padding: screenSize.dynamicWidth >= 1000 ? "80px 50px" : "10px",
									borderRadius: "15px",
								}}>
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={15}
									xl={15}
									className="check-in-wrapper-col"
									style={{
										marginTop:
											screenSize.dynamicWidth >= 1000 ? "50px" : "10px",
									}}>
									<div className="textCenter">
										<img
											src={circleInsideLogo}
											style={{ maxWidth: "250px", width: "100%" }}
											alt=""
										/>
									</div>
									{/* <Clock
									format={"hh:mm:ss A"}
									ticking={true}
									date={time}
									// timezone={"America/New_York"}
								/> */}
									<h1
										style={{
											color: "#F15733",
											fontSize:
												screenSize.dynamicWidth >= 1000 ? "40px" : "30px",
											marginTop: "15px",
										}}
										className="textCenter check-in-wrapper-h1">
										<Clock
											format={"hh:mm:ss A"}
											ticking={true}
											date={actualTime}
											// timezone={"America/New_York"}
										/>
										{/* {dayjs(actualTime).format("hh:mm:ss A")} */}
									</h1>
									<h1
										style={{
											color: "white",
											marginTop: "15px",
											fontSize: "20px",
										}}
										className="textCenter">
										{time
											? dayjs(time, "YYYY-MM-DD hh:mm:ss A").format(
													"MMMM DD, YYYY"
											  )
											: dayjs().format("MMMM DD, YYYY")}
									</h1>
								</Col>
								<Col xs={24} sm={24} md={24} lg={9} xl={9}>
									<p className="labelName textCenter -poppins_regular18">
										Enter Check-In ID
									</p>
									<ResultComponent
										loading={
											isLoading === true || findLoading === true
												? true
												: false
										}
										result={result}
										onChangeFn={onChangeInput}
									/>
									<KeyPadComponent
										loading={
											isLoading === true || findLoading === true
												? true
												: false
										}
										onClick={onClick}
										onSubmit={submitFn}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				)
			}
			{isModalOpen ? (
				<Modal
					title={`Hello ${employee.employee_name} `}
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
					className="checkInModal"
					footer={[]}
					width={400}
					centered>
					<Row gutter={16}>
						<Col span={24}>
							<p className="like-btn poppins_medium-20">
								Would you like to{" "}
								{`${employee.check_in_status ? "Check Out" : "Check In"}?`}
							</p>
						</Col>
						<Col span={12} className="coloredBtn">
							<Button
								disabled={isLoading === true || findLoading === true ? true : false}
								loading={isLoading === true || findLoading === true ? true : false}
								className=""
								onClick={() => {
									handleClick(employee);
								}}>
								Yes
							</Button>
						</Col>
						<Col span={12} className="borderBtn" onClick={handleCancel}>
							<Button
								disabled={isLoading === true || findLoading === true ? true : false}
								className="">
								No
							</Button>
						</Col>
					</Row>
				</Modal>
			) : null}

			{			
				toasterBox ? (
					<Modal 
					style={{ borderRadius: '15px' }}
					// title="toaster box"
					open={toasterBox}
					onOk={handleToasterOk}
					onCancel={handleToasterCancel}
					className="toaster-modal"
					footer={[]}
					width={300}
					height={'auto'}
					// toasterMsg.type === "success" ? "#07bc0c" : "#e74c3c"
					centered > 
						<Row style={{ background: toasterBgcolor, padding: '20px', color: 'white'}}>
							<Col span={2}>
								{
									toasterMsg.type === "error" ? 
									<ExclamationCircleOutlined style={{ fontSize: '15px'}}/> : 
									<CheckCircleOutlined style={{ fontSize: '15px'}}/>
								}
								 
							</Col>
							<Col span={20}> 
								{toasterMsg?.message}
							</Col> 
						</Row>

					</Modal>
				) : null
			}
		</div>
	);
};

export default CheckIn;
