import { Button, Col, Form, Input, Modal, Row } from "antd";

const MasterModal = (props) => {
	
	const { initialValues, title, isModalOpen, setIsModalOpen, handleFinish, loading, masterType, popupType } = props;
    console.log(initialValues, "initilavalue")
	const [form] = Form.useForm();
	
	return (
		<Modal
			centered
			title={title}
			open={isModalOpen}
			onCancel={() => {
				setIsModalOpen(false);
			}}
			footer={[
				<Button
					key="1"
					loading={loading}
					type="primary"
					onClick={form.submit}
					htmlType="submit">
					Yes
				</Button>,
				<Button
					key="2"
					onClick={() => {
						setIsModalOpen(false);
					}}>
					No
				</Button>,
			]}>
			<Form
            style={{marginTop:'20px'}}
				initialValues={popupType === "Add" ? null : initialValues}
				// layout={layout ? layout : "vertical"}
				form={form}
				onFinish={(values) => handleFinish(values)}>
				<Row>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true,
									message: "Please Enter the Name",
								},
							]}
							name={masterType}
							label="Name">
							<Input type="text" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default MasterModal;
