import { mainApi } from "../mainApi"

//Auth Api
export const essentialApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        essentailAdd: builder.mutation({
            query: ({ url, formData }) => ({
                url: `company/${url}`,
                method: "POST",
                body: formData
            }),
            invalidatesTags : ["Essential"]
        }),
    // New
        listEssential: builder.query({
            query: (list) => {
                return `company/essential-list?select=${list}`
            },
            providesTags : ["Essential", "Master"]
        })
    }),

})

export const {
    useEssentailAddMutation,
    useListEssentialQuery
} = essentialApi
