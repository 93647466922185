import { EllipsisOutlined, EyeOutlined, FormOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Row, Select, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListVendorEmployeeQuery } from "../../../features/api/adminApi/vendor_api";
import Loader from "../../loader/Loader";
import { _handleEditNavigation } from "../../../global/helpers";

const EmployeeDetails = ({ vendorId, navigate }) => {
	//Hooks
	// const navigate = useNavigate();
	const [vendorEmployeeData, setVendorEmployeeData] = useState([]);
	//api
	const {
		data: vendorEmployee,
		isFetching: vendorEmployeeFetching,
		isLoading: viewEmployeeLoading,
		isSuccess,
	} = useListVendorEmployeeQuery(vendorId);

	//states
	const columns = [
		{
			title: "Employee ID",
			dataIndex: "reference_no",
		},
		{
			title: "Check-in ID",
			dataIndex: "check_in_id",
		},
		{
			title: "Employee  Name",
			render: (_, record) => `${record?.first_name} ${record?.last_name}`,
		},
		{
			title: "Email",
			dataIndex: "email",
		},
		{
			title: "Phone #",
			dataIndex: "phone_number",
		},
		{
			title: "Role",
			dataIndex: "role",
		},
		{
			title: "Department",
			dataIndex: "department",
		},
		{
			title: "Current Status",
			dataIndex: "current_status",
			render: (_, record) =>
				record.check_in_status === true ? (
					<span className="check_in_status">Check In</span>
				) : (
					<span className="check_out_status">Check Out</span>
				),
		},
		{
			title: "Since",
			dataIndex: "joined_date",
		},
		{
			title: "Status",
			dataIndex: "status",
			render: (_, record) => (
				<Select
					className="event-none"
					defaultValue={record?.status}
					options={[
						{
							label: "Active",
							value: 1,
						},
						{
							label: "In Active",
							value: 0,
						},
					]}
				/>
			),
		},
		{
			title: "Action",
			align: "center",
			render: (_, record) => {
				const items = [
					{
						label: "View",
						key: "1",
						icon: <EyeOutlined />,
						onClick: () => {
							navigate(`/admin/employees/view/${record?.id}`);
							// dispatch(setEmployee(record))
						},
					},
					{
						label: "Edit",
						key: "2",
						icon: <FormOutlined />,
						onClick: () => {
							navigate(`/admin/employees/edit/${record?.id}`);
							// dispatch(setEmployee(record))
						},
					},
					// {
					//     label: "Delete",
					//     key: "3",
					//     icon: <DeleteOutlined />,
					//     onClick: () => {},
					// },
				];
				return (
					<Dropdown
						className="main-modal"
						placement="bottom"
						menu={{
							items,
						}}>
						<EllipsisOutlined
							style={{
								fontSize: "20px",
								cursor: "pointer",
							}}
							key="ellipsis"
						/>
					</Dropdown>
				);
			},
		},
	];

	useEffect(() => {
		if (vendorEmployee) {
			setVendorEmployeeData(vendorEmployee.data.data);
		}
	}, [vendorEmployee]);

	return (
		<>
			{viewEmployeeLoading === true || vendorEmployeeFetching === true ? (
				<Loader />
			) : (
				isSuccess && (
					<div>
						<Row gutter={[50, 10]}>
							<Col span={24}>
								<Typography.Title level={5}>Employee Details</Typography.Title>
								{/* <Row justify={"space-between"}>
									<Col span={12}>
										<Typography.Title level={5}>
											Employee Details
										</Typography.Title>
									</Col>
									<Col
										span={12}
										style={{
											textAlign: "end",
										}}>
										<Button
											type="primary"
											onClick={() =>
												_handleEditNavigation(
													navigate,
													`/admin/vendors/edit/${vendorId}`
												)
											}>
											Edit
										</Button>
									</Col>
								</Row> */}
								<Divider />
							</Col>
							<Col span={24}>
								<Table
									size="small"
									rowKey={(record) => record?.id}
									columns={columns}
									dataSource={vendorEmployeeData}
									pagination={{
										pageSize: 10,
										showSizeChanger: true,
										pageSizeOptions: [10, 25, 50, 100, 500],
									}}
									scroll={{
										x: 1300,
										y: 500,
									}}
								/>
							</Col>
						</Row>
					</div>
				)
			)}
		</>
	);
};

export default EmployeeDetails;
