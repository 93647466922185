import { createSlice } from "@reduxjs/toolkit";
import { cloneWith } from "lodash";
import errorMessage from "../../global/errorMessage/ErrorMessage";
import dayjs from "dayjs";

const timerSlice = createSlice({
	name: "time",
	initialState: {
		count: 0,
		time: null,
	},
	reducers: {
		setCounter: (state) => {
			// state.count += 1
			state.time = dayjs(state.time).add(1, "seconds");
		},
		setTimer: (state, actions) => {
			state.time = actions.payload;
		},
	},
});

export const { setTimer, setCounter } = timerSlice.actions;

export default timerSlice.reducer;
