import { message } from "antd";
import { mainApi } from "../mainApi";

//Auth Api
export const timesheetApi = mainApi.injectEndpoints({
	endpoints: (builder) => ({
		timesheetList: builder.query({
			query: (params) => `/company/employee/entry/list?${params}`,
			providesTags: ["Timesheet"],
		}),
		timesheetEmployeeAgencyList: builder.query({
			query: (params) => {
				return `/company/employee/entry/agencylist?${params}`;
			},
			providesTags: ["Timesheet"],
		}),
		updateTimeSheet: builder.mutation({
			query: ({ formData, id }) => {
				return {
					url: id ? `/company/timesheet/update/${id}` : `/company/timesheet/update`,
					method: "POST",
					body: formData,
				};
			},
			invalidatesTags: ["Timesheet"],
		}),
		addEditTimesheet: builder.mutation({
			query: ({ formData, id, type }) => {
				return {
					url:
						type === "add"
							? `/company/timesheet/create/${id}`
							: type === "edit"
							? `/company/timesheet/update/${id}`
							: `/company/timesheet/delete/${id}`,
					method: type === "delete" ? "DELETE" : "POST",
					body: formData,
				};
			},
			invalidatesTags: ["Timesheet"],
		}),

		deleteTimesheet: builder.mutation({
			query: ({ id }) => {
				return {
					url: `/company/timesheet/delete/${id}`,
					method: "Delete",
				};
			},
			invalidatesTags: ["Timesheet"],
		}),

		timesheetDownload: builder.mutation({
			query: (params) => {
				return {
					url: "/company/timesheet/download",
					method: "POST",
					body: {
						search: params.search ?? "",
						from_date: params.from_date ?? "",
						to_date: params.to_date ?? "",
						employee: params.employee ?? "",
						agent: params.agent ?? "",
						type: params.type ?? "",
						file_type: params.file_type ?? ""
					},
					responseHandler: async (res) => {
						if (res.status === 200) {
							return await res.blob();
						} else if (res?.status === 204) {
							message.info("No Data");
						} else {
							return await res.text();
						}
					},
				};
			},
		}),
		employeeTimesheetDownload: builder.mutation({
			query: (params) => {
				return {
					url: `company/employee/entry/download/${params.id}`,
					method: "POST",
					body: {
						from_date: params.from_date ?? "",
						to_date: params.to_date ?? "",
						file_type: params.file_type ?? ""
					},

					responseHandler: async (res) => {
						if (res.status === 200) {
							return await res.blob();
						} else if (res?.status === 204) {
							message.info("No Data");
						} else {
							return await res.text();
						}
					},
				};
			},
		}),
		timesheetMail: builder.mutation({
			query: (params) => {
				return {
						url: "/company/timesheet/mail",
						method: "POST",
						body: {
							search: params.search ?? "",
							from_date: params.from_date ?? "",
							to_date: params.to_date ?? "",
							employee: params.employee ?? "",
							agent: params.agent ?? "",
							type: params.type ?? ""
						},
					}
			}
		}),
		timesheet: builder.mutation({
			query: (params) => ({
				url: "/company/timesheet/mail",
				method: "POST",
				body: {
					search: params.search ?? "",
					from_date: params.from_date ?? "",
					to_date: params.to_date ?? "",
				},
			}),
		}),
		singleEmployeeTimesheet: builder.query({
			query: ({ id, params }) => {
				return {
					url: `/company/employee/entry/view/${id}?${params}`,
				};
			},
			providesTags: ["Timesheet"],
		}),
		employeeTimesheetMail: builder.mutation({
			query: (params) => ({
				url: `company/employee/entry/mail/${params.id}`,
				method: "POST",
				body: {
					from_date: params.from_date ?? "",
					to_date: params.to_date ?? "",
				},
			}),
		}),
	}),
});

export const {
	useTimesheetListQuery,
	useTimesheetEmployeeAgencyListQuery,
	useDeleteTimesheetMutation,
	useTimesheetDownloadMutation,
	useTimesheetMailMutation,
	useEmployeeTimesheetDownloadMutation,
	useEmployeeTimesheetMailMutation,
	useUpdateTimeSheetMutation,
	useSingleEmployeeTimesheetQuery,
	useAddEditTimesheetMutation,
	useTimesheetMutation,
} = timesheetApi;
