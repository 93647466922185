import { Col, Row, Spin, Table, Tabs, Tooltip, message, Input, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/main_layout/MainLayout'
import { useListEssentialQuery } from '../../features/api/adminApi/essential';
import { DeleteOutlined, EditFilled, FilterOutlined } from '@ant-design/icons';
import MasterModal from '../../components/mastermodal/MasterModal';
import { useAddMasterNameMutation, useDeleteMasterNameMutation, useEditMasterNameMutation } from '../../features/api/adminApi/master_api';
import errorMessage from '../../global/errorMessage/ErrorMessage';
import CustomerModal from "../../components/custommodal/CustomerModal";
const { Search } = Input;


const AdminMaster = () => {

    const [key, setKey] = useState(1);
    const [editName, setEditName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [typeId, setTypeId] = useState(0);
    const [masterType, setMasterType] = useState("");
    const [addButton, setAddButton] = useState("");
    const [popupType, setPopupType] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    
    const {
            data: essentialData, 
            isSuccess, 
            isLoading: essentialLoading,
            isFetching: essentialFetching,
            
        } =  useListEssentialQuery("business_type,role,department,vendor")

    const [editMasterName, {isLoading: editMasterLoading}] = useEditMasterNameMutation()
    const [addMasterName, {isLoading: addMasterLoading}] = useAddMasterNameMutation()
    const [deleteMasterName, {isLoading: deleteMasterLoading}] = useDeleteMasterNameMutation()

    const handleFinish = (values) => {
        let newMasterType = ""
        let params = {}
        const newValues = {...values, company_id : companyId}

        if(masterType === "business_type"){
            newMasterType = masterType.replace(/_/g, "-")
        }else if(masterType === "department_type"){
            newMasterType = masterType.split('_')[0]
        }else{
            newMasterType = masterType
        }
        if(popupType === "Add"){
            params = {
                formData: values,
                newMasterType: newMasterType
            }
        }else if(popupType === "Delete"){
            params = {
                id: typeId,
                newMasterType: newMasterType
            }
        }else{
            params = {
                id: typeId,
                formData: newValues,
                newMasterType: newMasterType
            }
        }
        
        popupType === "Add" ? 

            addMasterName(params)
                .unwrap()
                .then((res) => {
                    message.success(res.message);
                    setIsModalOpen(false)
                })
                .catch((err) => {
                    errorMessage(err?.data?.error)
                    setIsModalOpen(false)
                })

        : popupType === "Edit" ?

            editMasterName(params)
            .unwrap()
                .then((res) => {
                    message.success(res.message);
                    setIsModalOpen(false)
                })
                .catch((err) => {
                    errorMessage(err?.data?.error)
                    setIsModalOpen(false)
                })

        : deleteMasterName(params)
            .unwrap()
            .then((res) => {
                message.success(res.message);
                setIsDeleteModalOpen(false)
            })
            .catch((err) => {
                errorMessage(err?.data?.error)
                setIsDeleteModalOpen(false)
            })
        
    }

    const breadcrumbs = [
        {
            title: "Dashboard",
            href: "/admin/dashboard",
        },
        {
            title: "Admin Master",
            // href: location.pathname,
        },
    ]

    const columns = [
        {
			title: 'Serial No',
            dataIndex: 'index',
            key: 'index',
            render: (_, __, index) => index + 1,
		},
        {
			title: "Name",
			dataIndex: key === 1 ? "business_type" 
                        : key === 2 ? "role"
                        :key === 3 ? "department_type" :  null,
		},
        {
			title: "Action",
			align: "center",
			render: (_, record) => {
				return (
					<>
						<Row gutter={15} justify={"center"}>
							<Col>
								<Tooltip title="Edit" className={""}>
									<EditFilled onClick={() => {
                                                                setIsModalOpen(true)
                                                                setCompanyId(record?.company_id)
                                                                setTypeId(record?.id)
                                                                setEditName(record)
                                                                setPopupType("Edit")
                                                            }}/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip
									title="Delete"
									style={{ paddingTop: "10px" }}
									className={""}>
									<DeleteOutlined onClick={() => {
                                                                setIsDeleteModalOpen(true)
                                                                setTypeId(record?.id)
                                                                setPopupType("Delete")
                                                            }}/>
								</Tooltip>
							</Col>
						</Row>
					</>
				);
			},
		},
    ]
    
    useEffect(() => {
        if(key === 1){
            setMasterType("business_type")
            setAddButton("Business Type")
        }else if(key === 2){
            setMasterType("role")
            setAddButton("Role")
        }else if(key === 3){
            setMasterType("department_type")
            setAddButton("Department")
        }
    }, [key])

  return (
    <Spin spinning={false}>
        <MainLayout title={"Master"}
            breadcrumbs={breadcrumbs}
            upload={[]} 
            button={[{
                label: `Add ${addButton}`,
                func: () => {
                    setIsModalOpen(true)
                    setPopupType("Add")
                },
            },]} 
            banner={false}>
            <Row gutter={[30,30]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Tabs className='master-tab'
                        defaultActiveKey={1}
                        activeKey={key}
                        onChange={(key) => {
                            setKey(key);
                        }}
                        tabPosition={"top"}
                        tabBarGutter={100}
                        type={"line"}
                        items={[
                            {
                                label: "Business Type",
                                key: 1,
                            },
                            {
                                label: "Role",
                                key: 2,
                            },
                            {
                                label: "Department",
                                key: 3,
                            },
                            
                        ]}>
                    </Tabs>
                    
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Spin spinning={essentialFetching}>
                        {isSuccess && (
                            <>
                                {key === 1 ? (
                                    <div>
                                        {/* <section className="outlet_top">
                                            <section className="outlet_top_search_wrapper">
                                                <Search
                                                    placeholder="Search Name"
                                                    loading={newFilterFetching}
                                                    onSearch={(value) => {
                                                        const setting = {
                                                            ...searchParams,
                                                            search : value
                                                        }
                                                        setSearchParams(setting)
                                                    }}
                                                    onChange={(event) => {
                                                        const setting = {
                                                            ...searchParams,
                                                            search : event.target.value
                                                        }
                                                        setSearchParams(setting)
                                                    }}
                                                    style={{
                                                        width: 250,
                                                    }}
                                                />
                                                <FilterOutlined
                                                    onClick={() => {
                                                        setFilterOption(!filterOptions);
                                                    }}
                                                    style={{ fontSize: "26px", cursor: "pointer" }}
                                                />
                                            </section>
                                        </section> */}
                                        <div className="outlet_table">
                                            <Table
                                                size="small"
                                                rowKey={(record)=> record.id}
                                                columns={columns}
                                                loading={essentialLoading}
                                                dataSource={essentialData?.data?.business_types}
                                                // pagination={{
                                                //     total: total ?? 0,
                                                //     defaultPageSize: 10,
                                                //     pageSizeOptions: [10, 25, 50, 100, 500],
                                                //     showSizeChanger: true,
                                                //     onChange: (page, pageSize) => {
                                                //         const setting = {
                                                //             ...searchParams,
                                                //             page,
                                                //             per_page: pageSize,
                                                //         }
                                                //         setSearchParams(setting)
                                                //     },
                                                // }}
                                                scroll={{
                                                    x: 700,
                                                    y: 400,
                                                }}
                                            />
                                        </div>
                                    </div>

                                ) : key === 2 ? (
                                    <div>
                                    
                                    <div className="outlet_table">
                                        <Table
                                            size="small"
                                            rowKey={(record)=> record.id}
                                            columns={columns}
                                            loading={essentialLoading}
                                            dataSource={essentialData?.data?.roles}
                                            // pagination={{
                                            //     total: total ?? 0,
                                            //     defaultPageSize: 10,
                                            //     pageSizeOptions: [10, 25, 50, 100, 500],
                                            //     showSizeChanger: true,
                                            //     onChange: (page, pageSize) => {
                                            //         const setting = {
                                            //             ...searchParams,
                                            //             page,
                                            //             per_page: pageSize,
                                            //         }
                                            //         setSearchParams(setting)
                                            //     },
                                            // }}
                                            scroll={{
                                                x: 700,
                                                y: 400,
                                            }}
                                        />
                                    </div>
                                </div>
                                        ) : key === 3 ? (
                                            <div>
                                                <div className="outlet_table">
                                                    <Table
                                                        size="small"
                                                        rowKey={(record)=> record.id}
                                                        columns={columns}
                                                        loading={essentialLoading}
                                                        dataSource={essentialData?.data?.departments}
                                                        // pagination={{
                                                        //     total: total ?? 0,
                                                        //     defaultPageSize: 10,
                                                        //     pageSizeOptions: [10, 25, 50, 100, 500],
                                                        //     showSizeChanger: true,
                                                        //     onChange: (page, pageSize) => {
                                                        //         const setting = {
                                                        //             ...searchParams,
                                                        //             page,
                                                        //             per_page: pageSize,
                                                        //         }
                                                        //         setSearchParams(setting)
                                                        //     },
                                                        // }}
                                                        scroll={{
                                                            x: 700,
                                                            y: 400,
                                                        }}
                                                    />
                                                </div>
                                        </div>
                                ): null}
                            </>
                        )}
					</Spin>
				</Col>
            </Row>
            {isModalOpen ? (
                <MasterModal 
                    initialValues={editName}
                    title={popupType+" "+addButton}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleFinish = {handleFinish}
                    loading={
                                popupType === "Add" 
                                ? addMasterLoading 
                                : editMasterLoading 
                            }
                    masterType={masterType}
                    popupType={popupType}
                    />
			    ) : null
            }
            {isDeleteModalOpen ? (
					<CustomerModal
						handleFinish={(values) => handleFinish(values)}
						title={popupType+" "+addButton}
						setIsModalOpen={setIsDeleteModalOpen}
						isModalOpen={isDeleteModalOpen }
						loading={deleteMasterLoading}>
						Are you sure to delete?
					</CustomerModal>
				) : null
            }
        </MainLayout>
    </Spin>
  )
}

export default AdminMaster