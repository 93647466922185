import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { mainApi } from "../api/mainApi"
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage"
import authReducer from "../slice/authSlice"
import customReducer from "../slice/customSlice"
import customerReducer from "../slice/customerSlice"
import documentReducer from "../slice/documentSlice"
import timerReducer from "../slice/timeSlice"




const persistConfig = {
	key: "root",
	version: 1,
	storage,
	blacklist: [mainApi.reducerPath, "employee"],
}
const rootReducer = combineReducers({
	[mainApi.reducerPath]: mainApi.reducer,
	auth: authReducer,
	custom: customReducer,
	customer: customerReducer,
	document: documentReducer,
	time: timerReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(mainApi.middleware),
})

export const persistor = persistStore(store)
