import { message } from "antd"
import errorMessage from "../../global/errorMessage/ErrorMessage"
import { mainApi } from "./mainApi"

//checkInOut Api
export const checkInOut = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        companyInfo: builder.query({
            query: ( slug ) => `/company/info/${slug}`,
        }),
        serverTime: builder.query({
            query: () => `/company/current/time`,
        }),
        employeeFindApi: builder.mutation({
            query: (formData) => ({
                url: "/company/employee/validation",
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["CheckIn"],
        }),
        employeeCheckInOutApi: builder.mutation({
            query: (formData) => ({
                url: "/company/employee/entry/time",
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["CheckIn"],
        }),
    }),
})

export const { 
                useEmployeeFindApiMutation,
                useEmployeeCheckInOutApiMutation,
                useServerTimeQuery,
                useCompanyInfoQuery
             } = checkInOut
