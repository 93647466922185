import { Button, Form, Modal } from "antd"
import dayjs from "dayjs"
import React, { useEffect } from "react"

export default function DropdownModal(props) {
    const {
        isModalOpen,
        loading,
        setIsModalOpen,
        handleFinish,
        children,
        title,
        layout,
        initialValues,
        setConfrim,
    } = props
    const [form] = Form.useForm()

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue({
                ...initialValues,
            })
        }
    }, [initialValues])
    return isModalOpen ? (
        <Modal
            centered
            title={title}
            open={isModalOpen}
            onCancel={() => {
                setIsModalOpen(false)
            }}
            footer={[
                <Button
                    key="1"
                    loading={loading}
                    type="primary"
                    onClick={form.submit}
                    htmlType="submit">
                    Yes
                </Button>,
                <Button
                    key="2"
                    onClick={() => {
                        if (setConfrim) {
                            setConfrim(true)
                        }
                        setIsModalOpen(false)
                    }}>
                    No
                </Button>,
            ]}>
            <Form
                initialValues={initialValues ? {} : {}}
                layout={layout ? layout : "vertical"}
                form={form}
                onFinish={(values) => handleFinish(values)}>
                {children}
            </Form>
        </Modal>
    ) : null
}
