import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactGoogleMapsLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

export default function AddressSuggestion({form, viewAddress}) {

  const [search, setSearch] = useState("")
  const [value, setValue] = useState("")

  const handleNoResult = () => {
      // console.log("No results for ", search)
  }

  const handleStatusUpdate = (status) => {
      // console.log(status)
  }

  const handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
      setSearch("")
      setValue(geocodedPrediction.formatted_address)
      form.setFieldValue("address", geocodedPrediction.formatted_address)
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    setValue(e.target.value)
    form.setFieldValue("address", e.target.value)
  }

  useEffect(() =>{
    if(viewAddress){
      setValue(viewAddress)
    }
  }, [viewAddress])

  return (
            <ReactGoogleMapsLoader
                  params={{
                          key: `AIzaSyBpPnB6Le56oWSrap61sQsrZY3HRtr_cQU`,
                          libraries: "places, geocode",
                      }}
                  render={(googleMaps) =>
                      googleMaps && (
                          <ReactGooglePlacesSuggest
                              googleMaps={googleMaps}
                              autocompletionRequest={{
                                  input: search,
                                  componentRestrictions: {
                                      country: ['ca']
                                  }
                              }}
                              onNoResult={handleNoResult}
                              onSelectSuggest={handleSelectSuggest}
                              onStatusUpdate={handleStatusUpdate}
                              textNoResults="My custom no results text"
                              customRender={(prediction) => (
                                  <div className="customWrapper">
                                      {prediction
                                          ? prediction.description
                                          : "My custom no results text"}
                                  </div>
                              )}>
                                  <Input
                                      className="full_width"
                                      type="text"
                                      value={value}
                                      placeholder="Search a location"
                                      onChange={handleInputChange}
                                  />
                          </ReactGooglePlacesSuggest>
                      )
                  }
            />
  )
}
