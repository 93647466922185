import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	message,
	Radio,
	Row,
	Select,
	Typography,
	Upload,
} from "antd";
import Loader from "../../loader/Loader";
import { CheckOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEssentialQuery } from "../../../features/api/adminApi/vendor_api";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteDocumentMutation } from "../../../features/api/adminApi/employee_api";
import { setVendorDocManually } from "../../../features/slice/documentSlice";
import errorMessage from "../../../global/errorMessage/ErrorMessage";
import AddressSuggestion from "../../address_suggestion/AddressSuggestion";

import { PatternFormat } from "react-number-format";
import {
	_handleEditNavigation,
	autoFormatPhoneNumber,
	beforeUpload,
	disabledDate,
} from "../../../global/helpers";

const VendorInformation = ({
	vendorId,
	navigate,
	vendorFetching,
	vendorLoading,
	isSucess,
	viewEmployee,
	handleVendorSave,
	setVendorDocs,
	vendorDocs,
	setDeleteShow,
	setRemoveDoc,
	deleteShow,
}) => {
	//Hooks
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [value, setValue] = useState();
	//states
	const [businessType, setBusinessType] = useState(null);

	//Api
	const {
		data: essentialApi,
		isLoading: essentialLoading,
		isFetching: essentialFetching,
	} = useEssentialQuery();
	const [deleteDocument, { isLoading: deleteLoading }] = useDeleteDocumentMutation();

	//function
	const handleDeleteDocument = (file) => {
		if (file.id) {
			deleteDocument(file.id)
				.unwrap()
				.then((res) => {
					dispatch(
						setVendorDocManually({ id: file.id, type: "delete", name: "vendorDoc" })
					);
					message.success("Document deleted successfully");
				})
				.catch((err) => errorMessage(err?.data?.error));
		}
	};

	const handleChange = (info) => {
		const id = info.file.uid;
		if (info.file.status === "done") {
			setVendorDocs((state) => [...state, info?.file]);
			dispatch(setVendorDocManually({ file: info.file, type: "docs", name: "vendorDoc" }));
		} else if (info.file.status === "removed") {
			setVendorDocs((state) => {
				return state?.filter((item) => item.uid !== id);
			});
		}
	};
	const selectOptions = (array, name) => {
		return name === "business_type"
			? array?.map((item, index) => ({
					label: item?.business_type,
					value: item?.id,
			  }))
			: () => {};
	};

	useEffect(() => {
		if (essentialApi) {
			setBusinessType(essentialApi.data.business_types);
		}
	}, [essentialApi]);
	useEffect(() => {
		// if (viewEmployee) {

		// }
		setValue(viewEmployee?.address ? viewEmployee?.address : "");
	}, []);

	return (
		<>
			{vendorLoading === true ||
			vendorFetching === true ||
			deleteLoading === true ||
			essentialLoading === true ||
			essentialFetching === true ? (
				<Loader />
			) : (
				isSucess && (
					<>
						<Form
							labelWrap
							form={form}
							// preserve={true}
							initialValues={{
								business_name: viewEmployee.business_name ?? "",
								address: viewEmployee.address ?? "",
								phone_number: viewEmployee.phone_number ?? "",
								first_name: viewEmployee.first_name ?? "",
								last_name: viewEmployee.last_name ?? "",
								establishment_date:
									viewEmployee.establishment_date &&
									dayjs(viewEmployee.establishment_date),
								email: viewEmployee.email ?? "",
								business_type: viewEmployee.business_type ?? "",

								// password: viewEmployee.password,
								// c_password: viewEmployee.c_password,
								insured: viewEmployee.insured ?? 0,
								licensed: viewEmployee.licensed ?? 0,
								// about_company: viewEmployee.about_company,
								// bank_name: viewEmployee.bank_name,
								// beneficiary_name: viewEmployee.beneficiary_name,
								// account_number: viewEmployee.account_number,
								// notes: viewEmployee.notes,
								// alert: viewEmployee.alert,
								// status: viewEmployee.status,
								// company_id: viewEmployee.company_id,
								// reference_no: viewEmployee.reference_no,
							}}
							layout="horizontal"
							labelAlign="left"
							labelCol={{
								span: 10,
							}}
							wrapperCol={{
								span: 10,
							}}
							onFinish={handleVendorSave}>
							<Row gutter={[10, 10]}>
								<Col span={24}>
									<Typography.Title level={5}>
										Vendor Information
									</Typography.Title>
									{/* <Row justify={"space-between"}>
										<Col span={12}>
											<Typography.Title level={5}>
												Vendor Information
											</Typography.Title>
										</Col>
										<Col
											span={12}
											style={{
												textAlign: "end",
											}}>
											<Button
												type="primary"
												onClick={() =>
													_handleEditNavigation(
														navigate,
														`/admin/vendors/edit/${vendorId}`
													)
												}>
												Edit
											</Button>
										</Col>
									</Row> */}
									<Divider />
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={12}>
									<Row>
										<Col span={24}>
											<Form.Item
												name={"business_name"}
												label={"Business Name"}>
												<Input placeholder="Business Name" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item name={"address"} label={"Company address"}>
												<AddressSuggestion
													form={form}
													viewAddress={value}
												/>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item name={"first_name"} label={"First Name"}>
												<Input placeholder="First Name" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item name={"last_name"} label={"Last Name"}>
												<Input placeholder="Last Name" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item name={"phone_number"} label={"Phone Number"}>
												<InputNumber
													maxLength={15}
													className="full_width"
													onChange={(value) => {
														form.setFieldValue(
															"phone_number",
															autoFormatPhoneNumber(value)
														);
													}}
												/>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												name={"establishment_date"}
												label={"Establishment Date"}>
												<DatePicker
													disabledDate={disabledDate}
													// className="full_width event-none"
													className="full_width"
													placeholder="Establishment Date"
													// dateRender={true}
												/>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item name={"email"} label={"Email Id"}>
												<Input type="email" placeholder="Email Id" />
											</Form.Item>
										</Col>
										<Col span={24} className="d-none">
											<Form.Item name={"password"} label={"Password"}>
												<Input.Password placeholder="Password" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												name={"business_type"}
												label={"Business Type"}>
												<Select
													// className="event-none"
													placeholder="Business Type"
													options={selectOptions(
														businessType,
														"business_type"
													)}
												/>
											</Form.Item>
										</Col>
										{/* <Col span={24}>
                                        <Form.Item name="joined_date" label={"Joined Date"}>
                                            <DatePicker placeholder="Joined Date" />
                                        </Form.Item>
                                    </Col> */}
										<Col span={24}>
											<Form.Item name="insured" label={"Insured"}>
												<Radio.Group>
													<Radio value={1}>Yes</Radio>
													<Radio value={0}>No</Radio>
												</Radio.Group>
												{/* <Input  placeholder="Insured" /> */}
											</Form.Item>
										</Col>
										<Col
											span={24}
											//  className="event-none"
										>
											<Form.Item name="licensed" label={"Licensed"}>
												<Radio.Group>
													<Radio value={1}>Yes</Radio>
													<Radio value={0}>No</Radio>
												</Radio.Group>
												{/* <Input  placeholder="Insured" /> */}
											</Form.Item>
										</Col>
										<Col span={24} className={""}>
											<Form.Item name="document" label={"Document"}>
												<Upload
													beforeUpload={beforeUpload}
													listType="text"
													customRequest={async (options) => {
														const { onSuccess, file } = options;
														setVendorDocs((state) => {
															return [...state, file];
														});
														onSuccess("ok");
													}}
													multiple={true}
													action="https://jsonplaceholder.typicode.com"
													accept=".pdf,.png,.jpg,.xls"
													method="GET"
													fileList={vendorDocs}
													onRemove={(file) => {
														file.path = "vendor-document";
														setRemoveDoc(file);
														setDeleteShow(true);
														return false;
													}}
													onChange={(info) =>
														handleChange(info, "vendor-document")
													}>
													<div className="upload">
														<Button icon={<UploadOutlined />}>
															Upload Document
														</Button>
														<Button
															icon={<PlusOutlined />}
															className="inneraddbuttonInput"
														/>
													</div>
												</Upload>
											</Form.Item>
										</Col>
									</Row>
								</Col>
								<Col span={24} className="d-none">
									<Form.Item name={"company_id"} label={"Password"}>
										<Input.Password placeholder="Password" />
									</Form.Item>
								</Col>
								<Col span={24} className="d-none">
									<Form.Item name={"reference_no"} label={"Password"}>
										<Input.Password placeholder="Password" />
									</Form.Item>
								</Col>
								<Col span={24} className="d-none">
									<Form.Item name={"status"} label={"status"}>
										<Input placeholder="status" />
									</Form.Item>
								</Col>
								<Col className="employee_view_btn">
									<Button
										type="primary"
										htmlType="submit"
										className="saveButtonPrimary"
										icon={<CheckOutlined />}>
										Save
									</Button>
									{/* <Button className="cancelBtn">Cancel</Button> */}
								</Col>
							</Row>
						</Form>
					</>
				)
			)}
		</>
	);
};
export default VendorInformation;
