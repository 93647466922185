import React from "react";
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Typography,
	Upload,
} from "antd";
import Loader from "../../loader/Loader";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteDocumentMutation } from "../../../features/api/adminApi/employee_api";
import { setVendorDocManually } from "../../../features/slice/documentSlice";
import errorMessage from "../../../global/errorMessage/ErrorMessage";
import { CheckOutlined } from "@ant-design/icons";
import { _handleEditNavigation, beforeUpload } from "../../../global/helpers";

const BankingInformation = ({
	vendorFetching,
	vendorLoading,
	isSucess,
	viewEmployee,
	handleVendorSave,
	vendorBankDocs,
	setVendorBankDocs,
	deleteShow,
	setRemoveDoc,
	setDeleteShow,
	vendorId,
	navigate,
}) => {
	//Hooks
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	//states
	const vendorDocLoading = useSelector((state) => state.document.vendorDocLoading);
	const vendorBankDoc = useSelector((state) => state.document.vendorBankDoc);

	//Api

	const [deleteDocument, { isLoading: deleteLoading }] = useDeleteDocumentMutation();

	//function
	const handleDeleteDocument = (file) => {
		if (file.id) {
			deleteDocument(file.id)
				.unwrap()
				.then((res) => {
					dispatch(
						setVendorDocManually({ id: file.id, type: "delete", name: "vendorBankDoc" })
					);
					message.success("Document deleted successfully");
				})
				.catch((err) => errorMessage(err?.data?.error));
		}
	};

	const handleChange = (info) => {
		const id = info.file.uid;
		if (info.file.status === "done") {
			setVendorBankDocs((state) => [...state, info?.file]);
			dispatch(
				setVendorDocManually({ file: info.file, type: "docs", name: "vendorBankDoc" })
			);
		} else if (info.file.status === "removed") {
			setVendorBankDocs((state) => {
				return state?.filter((item) => item.uid !== id);
			});
		}
	};

	return (
		<>
			{vendorLoading === true ||
			vendorFetching === true ||
			deleteLoading === true ||
			vendorDocLoading === true ? (
				<Loader />
			) : (
				isSucess && (
					<>
						<Form
							labelWrap
							form={form}
							initialValues={{
								bank_name: viewEmployee?.bank_name,
								beneficiary_name: viewEmployee?.beneficiary_name,
								account_number: viewEmployee?.account_number,
							}}
							layout="horizontal"
							labelAlign="left"
							labelCol={{
								span: 4,
							}}
							wrapperCol={{
								span: 10,
							}}
							onFinish={handleVendorSave}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Row gutter={[10, 10]}>
									<Col span={24}>
										<Typography.Title level={5}>
											Bank Information
										</Typography.Title>
										{/* <Row justify={"space-between"}>
											<Col span={12}>
												<Typography.Title level={5}>
													Bank Information
												</Typography.Title>
											</Col>
											<Col
												span={12}
												style={{
													textAlign: "end",
												}}>
												<Button
													type="primary"
													onClick={() =>
														_handleEditNavigation(
															navigate,
															`/admin/vendors/edit/${vendorId}`
														)
													}>
													Edit
												</Button>
											</Col>
										</Row> */}
										<Divider />
									</Col>
									<Col span={24}>
										<Form.Item name="bank_name" label={"Bank Name"}>
											<Input placeholder="Bank Name" />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											name="beneficiary_name"
											label={"Beneficiary Name"}>
											<Input placeholder="Beneficiary Name" />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item name="account_number" label={"Account Number"}>
											<InputNumber
												className="full_width "
												maxLength={16}
												placeholder="Account Number"
											/>
										</Form.Item>
									</Col>
									<Col
										span={24}
										// className={vendorBankDocs?.length < 1 ? "event-none" : ""}>
									>
										<Form.Item name="bank_document" label={"Documents"}>
											<Upload
												beforeUpload={beforeUpload}
												listType="text"
												customRequest={async (options) => {
													const { onSuccess, file } = options;
													setVendorBankDocs((state) => {
														return [...state, file];
													});
													onSuccess("ok");
												}}
												multiple={true}
												action="https://jsonplaceholder.typicode.com"
												accept=".pdf,.png,.jpg,.xls"
												method="GET"
												fileList={vendorBankDocs}
												onRemove={(file) => {
													file.path = "vendor-document";
													setRemoveDoc(file);
													setDeleteShow(true);
													return false;
												}}
												onChange={(info) =>
													handleChange(info, "vendor-document")
												}>
												<div className="upload">
													<Button icon={<UploadOutlined />}>
														Upload Document
													</Button>
													<Button
														icon={<PlusOutlined />}
														className="inneraddbuttonInput"
													/>
												</div>
											</Upload>
										</Form.Item>
									</Col>
									<Col className="employee_view_btn">
										<Button
											type="primary"
											htmlType="submit"
											className="saveButtonPrimary"
											icon={<CheckOutlined />}>
											Save
										</Button>
										{/* <Button className="cancelBtn">Cancel</Button> */}
									</Col>
								</Row>
							</Col>
						</Form>
					</>
				)
			)}
		</>
	);
};
export default BankingInformation;
