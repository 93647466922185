import { mainApi } from "../mainApi";

export const superadmin_auth = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        //Login
        superadminLoginApi: builder.mutation({
            query: (formData) => ({
                url: `/super-admin/login`,
                method: "POST",
                body: formData
            }),
            invalidatesTags: ['CheckIn', 'Customer', 'Employee', 'Profile', 'Timesheet', 'Vendor']
        }),
    })
})

export const { useSuperadminLoginApiMutation } = superadmin_auth;