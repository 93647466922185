import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./responsive.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";
import { Provider } from "react-redux";
import { store, persistor } from "./features/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { StyleProvider } from "@ant-design/cssinjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<ConfigProvider
					theme={{
						token: {
							// colorBgLayout: "#e4e5e"
							// colorPrimaryBg: "#33383f",
							// colorPrimaryText : "red",
							colorPrimary: "#f15733",
							colorFillQuaternary: "#ededed",
							// colorFillContentHover: "red",
							// fontFamily: "AvenirBlack",
							// borderRadius : "30px"
						},
						components: {
							Layout: {
								colorBgHeader: "#22272f",
							},
							Button: {
								colorText: "#f15733",
								colorBorder: "#f15733",
								// colorBgContainer: "#f5f5f5",
								borderRadius: "30px",
							},
							Table: {
								// colorFillQuaternary :"red"
							},
						},
					}}>
					<StyleProvider hashPriority="high">
						<ToastContainer
							position="top-center"
							autoClose={2000}
							hideProgressBar={true}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss={false}
							pauseOnHover
							theme="colored"
						/>
						<Toaster position="top-center" />
						<App />
					</StyleProvider>
				</ConfigProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>
	// </React.StrictMode>
);
