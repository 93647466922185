import {setCustomerDocuments} from "../../slice/documentSlice"
import {mainApi} from "../mainApi"

export const customer_api = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        //Add Edit New
        addEditCustomer: builder.mutation({
            query: ({id, formData}) => {
                return {
                    url: id !== undefined ? `super-admin/customer/${id}` : "super-admin/customer",
                    method: "POST",
                    body: formData,
                }
            },
            invalidatesTags: ["Customer"],
        }),

        //list customer
        listCustomer: builder.query({
            query: (params) => `super-admin/customer?${params}`,
            providesTags: ["Customer"],
        }),

        //View customer
        viewCustomer: builder.query({
            query: (customerId) => `super-admin/customer/${customerId}`,
            async onQueryStarted(args, {dispatch, queryFulfilled, getState}) {
                const token = getState().auth.token
                const {data} = await queryFulfilled
                if (data.data.documents.length >= 1) {
                    dispatch(
                        setCustomerDocuments({
                            array: data.data.documents,
                            token: token,
                        })
                    )
                } else {
                    dispatch(
                        setCustomerDocuments({
                            array: [],
                        })
                    )
                }
            },
            providesTags: ["Customer"],
        }),

        //Add customer old
        addCustomer: builder.mutation({
            query: (formData) => ({
                url: `super-admin/customer`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Customer"],
        }),

        //Edit customer old
        editCustomer: builder.mutation({
            query: ({id, formData}) => ({
                url: `super-admin/customer/${id}`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Customer"],
        }),

        //Delete customer
        deleteCustomer: builder.mutation({
            query: (id) => ({
                url: `super-admin/customer/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Customer"],
        }),

        //Change Password
        editPassword: builder.mutation({
            query: ({formData, role}) => ({
                url:
                    role === "superAdmin"
                        ? `super-admin/change-password`
                        : role === "customer"
                        ? `company/change-password`
                        : `super-admin/customer/change-password`,
                method: "PUT",
                body: formData,
            }),
            invalidatesTags: ["Customer"],
        }),

        //Edit setting
        editSetting: builder.mutation({
            query: ({formData, id}) => ({
                url: `company/setting/${id}`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Customer", "Profile", "CheckIn"],
        }),

        //Edit Profile
        editProfile: builder.mutation({
            query: ({formData}) => ({
                url: `company/profile`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Customer"],
        }),

        //list setting
        listSetting: builder.query({
            query: () => `company/setting`,
            providesTags: ["Customer"],
        }),

        //Essential
        customerEssential: builder.query({
            query: () => `super-admin/essential-list?select=position`,
            providesTags: ["Customer"],
        }),

        //Delete Document
        deleteDocument: builder.mutation({
            query: (id) => ({
                url: `company/customer-document/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Customer"],
        }),

        //Add Customer positon
        addCustomerPosition: builder.mutation({
            query: (formData) => ({

                url: `super-admin/position`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Customer", "Master"],
        }),

        //Edit Customer positon
        editCustomerPosition: builder.mutation({
            query: ({formData, id}) => ({

                url: `super-admin/position/${id}`,
                method: "PUT",
                body: formData,
            }),
            invalidatesTags: ["Master"],
        }),
        
        //Delete Customer position
        deleteCustomerPosition: builder.mutation({
            query: (id) => ({
                url: `super-admin/position/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Customer", "Master"],
        }),

        //Delete company document
        deleteCustomerDocument: builder.mutation({
            query: (id) => ({
                url: `super-admin/customer-document/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Customer"],
        }),

        //Update the status

        updateCustomerStatus: builder.mutation({
            query: (params) => {
                return {
                    url: `super-admin/customer-status/${params.id}`,
                    method: "PATCH",
                    body: params.formData,
                }
            },
            invalidatesTags: ["Customer"],
        }),
        //customerLogoUpdate
        customerLogoUpdateAndDelete: builder.mutation({
            query: ({id, type, formData}) => {
                return {
                    url: `super-admin/customer/logo/${type}/${id}`,
                    method: "POST",
                    body: formData,
                }
            },
            invalidatesTags: ["Customer"],
        }),
    }),
})

export const {
    useAddEditCustomerMutation,
    useCustomerLogoUpdateAndDeleteMutation,
    useListCustomerQuery,
    useViewCustomerQuery,
    useAddCustomerMutation,
    useEditCustomerMutation,
    useDeleteCustomerMutation,
    useEditSettingMutation,
    useEditPasswordMutation,
    useCustomerEssentialQuery,
    useListSettingQuery,
    useEditProfileMutation,
    useAddCustomerPositionMutation,
    useDeleteCustomerDocumentMutation,
    useUpdateCustomerStatusMutation,
    useEditCustomerPositionMutation,
    useDeleteCustomerPositionMutation
} = customer_api
