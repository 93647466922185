import React, { useState } from "react"
import { Breadcrumb, Col, Layout, Row, theme } from "antd"
import { Button, Checkbox, Form, Input } from "antd"
import "../login.css"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setUser } from "../../../features/slice/authSlice.js"
import { useSuperadminLoginApiMutation } from "../../../features/api/superadminApi/superadmin_auth"
import Typography from "antd/es/typography/Typography"
import errorMessage from "../../errorMessage/ErrorMessage"
import circleInsideLogo from "../../../assets/circle-inside-logo.png"
import "./superadmin.css"

const { Content } = Layout

const SuperAdminLogin = () => {
    //Hooks
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //SuperAdmin Login Api
    const [superadminLoginApi, { isLoading }] = useSuperadminLoginApiMutation()

    const handleLogin = (values) => {
        superadminLoginApi(values)
            .unwrap()
            .then((res) => {
                // const { id, first_name, last_name, logo } = res.super_admin
                dispatch(setUser({ token: res?.token, super_admin_token: res?.token, role: "SuperAdmin", user: res?.super_admin }))
                navigate("/super-admin/dashboard")
            }).catch((err) => {
                errorMessage(err?.data?.error)
            })
    }

    return (
        <Layout className="layout">
            <div className="admin-login">
                <Row justify={"center"}>
                    <Col sapn={24}>
                        <div className="logo">
                            <img src={circleInsideLogo} alt="" />
                        </div>
                    </Col>
                    <Col span={24} className="title">
                        <Typography.Title style={{
                            textAlign: "center"
                        }}>
                            Super Admin
                        </Typography.Title>
                    </Col>

                    <Form
                        form={form}
                        layout={"vertical"}

                        onFinish={handleLogin}>
                        <Row justify={"center"}>
                            <Col span={24}>
                                <Form.Item rules={[{
                                    type: "email", 
                                    message: "Enter a valid email id"
                                }, {
                                    required: true,
                                    message: "Please enter email id"
                                }
                                ]} name={"email"} label={"Email ID"}>
                                    <Input placeholder="Email ID" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item rules={[{
                                    required: true
                                }
                                ]} name={"password"} label={"Password"}>
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button
                                    loading={isLoading}
                                    style={{
                                        width: "120px",
                                    }}
                                    type="primary"
                                    htmlType="submit">
                                    Login
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </div>
        </Layout>
    )
}

export default SuperAdminLogin
