import { EyeOutlined, FilterOutlined, FormOutlined, PlusSquareOutlined } from "@ant-design/icons"
import {
    Avatar,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Input,
    Row,
    Select,
    Spin,
    Table,
    Tabs,
    Typography,
} from "antd"
import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import MainLayout from "../../components/main_layout/MainLayout"
import "./notification.css"
import { useNotificationListQuery } from "../../features/api/adminApi/notification_api"
import dayjs from "dayjs"

//Tab
const Tab = ({ active }) => {
    const [filterOptions, setFilterOption] = useState(false)
    const options = [
        {
            label: "Emp. ID",
            value: "id",
        },
        {
            label: "Check-In ID",
            value: "check_in_id",
        },
        {
            label: "Employee Name",
            value: "employee_name",
        },
        {
            label: "Email",
            value: "email",
        },
        {
            label: "Phone",
            value: "phone",
        },
        {
            label: "Role",
            value: "role",
        },
        {
            label: "Department",
            value: "department",
        },
        {
            label: "Status",
            value: "status",
        },
    ]


    const { data: notificationData } = useNotificationListQuery()

    return (
        <Row
            style={{
                heignt: "100%",
            }}
            gutter={[10, 10]}>
            {active === 1 ? (
                <>
                    <Col span={24}>
                        <Typography.Title level={4}>All</Typography.Title>
                        <Divider style={{ margin: 0, marginBottom: '10px' }} />
                        <Row gutter={10}>
                            {notificationData && notificationData.data.data.map((notification, index) =>
                            (
                                <Col span={24} key={index} className="logs_user">
                                    <div className="notify_msg">
                                        <Col xs={4} sm={4} md={2} lg={1} xl={1}>
                                            <Avatar>{notification.message.charAt(0)}</Avatar>
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <p><span>{notification.message}</span></p>
                                            <p className="notify_subhead">{dayjs(notification.created_at).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                        </Col>
                                    </div>
                                </Col>
                            ))
                            }
                        </Row>
                    </Col>
                </>
            ) : active === 2 ? (
                <>
                    <Col span={24}>
                        <Typography.Title level={4}>Timesheet</Typography.Title>
                        <Divider style={{ margin: 0, marginBottom: '10px' }} />
                        <Row gutter={10}>
                            {notificationData && notificationData.data.data.map((notification, index) => {

                                if (notification.notification_type === "timesheet") {
                                    return (<Col span={24} key={index} className="logs_user">
                                        <div className="notify_msg">
                                            <Col xs={4} sm={4} md={2} lg={1} xl={1}>
                                                <Avatar>{notification.message.charAt(0)}</Avatar>
                                            </Col>
                                            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                                <p><span>{notification.message}</span></p>
                                                <p className="notify_subhead">{dayjs(notification.created_at).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                            </Col>
                                        </div>
                                    </Col>)
                                }
                            }
                            )}
                        </Row>

                    </Col>
                </>
            ) : active === 3 ? (
                <>
                    <Col span={24}>
                        <Typography.Title level={4}>Check In Early</Typography.Title>
                        <Divider style={{ margin: 0, marginBottom: '10px' }} />
                        <Row gutter={10}>
                            {notificationData && notificationData.data.data.map((notification, index) => {

                                if (notification.notification_type === "early_checkin") {
                                    return (<Col span={24} key={index} className="logs_user">
                                        <div className="notify_msg">
                                            <Col xs={4} sm={4} md={2} lg={1} xl={1}>
                                                <Avatar>{notification.message.charAt(0)}</Avatar>
                                            </Col>
                                            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                                <p><span>{notification.message}</span></p>
                                                <p className="notify_subhead">{dayjs(notification.created_at).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                            </Col>
                                        </div>
                                    </Col>)
                                }
                            }
                            )}
                        </Row>

                    </Col>
                </>
            ) : active === 4 ? (
                <>
                    <Col span={24}>
                        <Typography.Title level={4}>Check In Late</Typography.Title>
                        <Divider style={{ margin: 0, marginBottom: '10px' }} />
                        <Row>
                            {notificationData && notificationData.data.data.map((notification, index) => {
                                if (notification.notification_type === "late_checkin") {
                                    return (<Col span={24} key={index} className="logs_user">
                                        <div className="notify_msg">
                                            <Col xs={4} sm={4} md={2} lg={1} xl={1}>
                                                <Avatar>{notification.message.charAt(0)}</Avatar>
                                            </Col>
                                            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                                <p><span>{notification.message}</span></p>
                                                <p className="notify_subhead">{dayjs(notification.created_at).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                            </Col>
                                        </div>
                                    </Col>)
                                }
                            }
                            )}
                        </Row>
                    </Col>
                </>
            ) : active === 5 ? (
                <Col span={24}>
                    <Typography.Title level={4}>Off</Typography.Title>
                    <Divider style={{ margin: 0, marginBottom: '10px' }} />
                    <Row gutter={10}>
                        {notificationData && notificationData.data.data.map((notification, index) => {

                            if (notification.notification_type === "off") {
                                return (<Col span={24} key={index} className="logs_user">
                                    <div className="notify_msg">
                                        <Col xs={4} sm={4} md={2} lg={1} xl={1}>
                                            <Avatar>{notification.message.charAt(0)}</Avatar>
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <p><span>{notification.message}</span></p>
                                            <p className="notify_subhead">{dayjs(notification.created_at).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                        </Col>
                                    </div>
                                </Col>)
                            }
                        }
                        )}
                    </Row>

                </Col>
            ) : active === 6 ? (
                <Col span={24}>
                    <Typography.Title level={4}>Overtime Hours</Typography.Title>
                    <Divider style={{ margin: 0, marginBottom: '10px' }} />
                    <Row gutter={10}>
                        {notificationData && notificationData.data.data.map((notification, index) => {
                            if (notification.notification_type === "timesheet") {
                                return (<Col span={24} key={index} className="overtime_hours">
                                    <div className="notify_msg">
                                        <Col xs={4} sm={4} md={2} lg={1} xl={1}>
                                            <Avatar>{notification.message.charAt(0)}</Avatar>
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <p><span>{notification.message}</span></p>
                                            <p className="notify_subhead">{dayjs(notification.created_at).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                        </Col>
                                    </div>
                                </Col>)
                            }
                        }
                        )}
                    </Row>

                </Col>
            ) : null}
        </Row >
    )
}

const Notifications = () => {
    //Hooks
    const location = useLocation()

    //states
    const [key, setKey] = useState(1)

    //breadcrumbs
    const breadcrumbs = [
        {
            title: "Dashboard",
            href: "/admin/dashboard",
        },
        {
            title: "Notification",
            // href: location.pathname,
        },
    ]

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }
    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    return (
        <Spin spinning={false}>
            <MainLayout title={"Notification"} breadcrumbs={breadcrumbs} upload={[]} button={[]}>
                <Row className="wrapper">
                    <Col span={24}>
                        <Tabs
                            defaultActiveKey={1}
                            activeKey={key}
                            onChange={(key) => {
                                setKey(key)
                            }}
                            tabPosition={screenSize.dynamicWidth <= 1200 ? "top" : "left"}
                            items={[
                                {
                                    label: "All",
                                    key: 1,
                                    children: <Tab active={key} />,
                                },
                                // {
                                //     label: "Timesheet",
                                //     key: 2,
                                //     children: <Tab active={key} />,
                                // },
                                {
                                    label: "Check-In Early",
                                    key: 3,
                                    children: <Tab active={key} />,
                                },
                                {
                                    label: "Check-In Late",
                                    key: 4,
                                    children: <Tab active={key} />,
                                },
                                {
                                    label: "Off",
                                    key: 5,
                                    children: <Tab active={key} />,
                                },
                                {
                                    label: "Overtime Hours",
                                    key: 6,
                                    children: <Tab active={key} />,
                                },
                            ]}>

                        </Tabs>
                    </Col>
                    {/* <Col span={20}>
                        <Tab active={key}></Tab>
                    </Col> */}
                </Row>
            </MainLayout>
        </Spin>
    )
}

export default Notifications
