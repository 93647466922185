import { createSlice } from "@reduxjs/toolkit"
import { cloneWith } from "lodash"
import errorMessage from "../../global/errorMessage/ErrorMessage"

const documentSlice = createSlice({
    name: "document",
    initialState: {
        employeeDoc: [],
        customerDoc: [],
        vendorDoc: [],
        vendorBankDoc: [],
        vendorAddDoc: [],
        loading: false,
        vendorDocLoading: false,
        // vendorBankDoc: [],
        // vendorAddDoc: [],
    },
    reducers: {

        setCustomerDocuments: (state, actions) => {
            const { array, token } = actions.payload
            if (array.length >= 1) {
                state.loading = true
                // const data = array.map((each, index) => {
                //     let length = each.document.split(".")[0].split("/").length - 1
                //     let filename = each.document.split(".")[0].split("/")[length]
                //     let type = ""
                //     const res = async () =>
                //         await fetch(
                //             `https://tevola-time-clock.stagingurl.tk/api/company/download?file_name=${String(
                //                 each.document
                //             )}`,
                //             {
                //                 headers: {
                //                     authorization: `Bearer ${token}`,
                //                 },
                //             }
                //         ).then((res) => {
                //             type = res.type
                //             res.blob()
                //         })

                //     const myFile = new File([res()], `${filename}_${index}`, {
                //         type: type,
                //     })
                //     myFile.id = each.id
                //     return myFile
                // })
                const data = array.map((doc) => {
                    return {
                        name: doc.document,
                        id: doc.id,
                        uid: doc.id,
                        url: `${process.env.REACT_APP_IMG_URL}${doc.document}`,
                        status: "done"
                    }
                })
                state.customerDoc = data
                state.loading = false
            } else {
                state.customerDoc = []
                state.loading = false
            }
        },

        setEmployeeDocuments: (state, actions) => {
            const { array, token } = actions.payload
            if (array.length >= 1) {
                state.loading = true
                // const data = array.map((each, index) => {
                //     let length = each.document.split(".")[0].split("/").length - 1
                //     let filename = each.document.split(".")[0].split("/")[length]
                //     let type = ""
                //     const res = async () =>
                //         await fetch(
                //             `https://tevola-time-clock.stagingurl.tk/api/company/download?file_name=${String(
                //                 each.document
                //             )}`,
                //             {
                //                 headers: {
                //                     authorization: `Bearer ${token}`,
                //                 },
                //             }
                //         ).then((res) => {
                //             type = res.type
                //             res.blob()
                //         })

                //     const myFile = new File([res()], `${filename}_${index}`, {
                //         type: type,
                //     })
                //     myFile.id = each.id
                //     return myFile
                // })
                const data = array.map((doc) => {
                    return {
                        name: doc.document,
                        id: doc.id,
                        uid: doc.id,
                        url: `${process.env.REACT_APP_IMG_URL}${doc.document}`,
                        status: "done"
                    }
                })
                state.employeeDoc = data
                state.loading = false
            } else {
                state.employeeDoc = []
                state.loading = false
            }
        },

        setvendorDocuments: (state, actions) => {
            const { array, token } = actions.payload
            if (array.length >= 1) {
                state.vendorDocLoading = true
                const data = array.map((doc) => {
                    return {
                        name: doc.document,
                        id: doc.id,
                        uid: doc.id,
                        url: `${process.env.REACT_APP_IMG_URL}/${doc.document}`,
                        status: "done"
                    }
                })
                //               {
                //  // const data = array.map((each, index) => {
                //                 //     let length = each.document.split(".")[0].split("/").length - 1
                //                 //     let filename = each.document.split(".")[0].split("/")[length]
                //                 //     let type = ""
                //                 //     const res = async () =>
                //                 //         await fetch(
                //                 //             `https://tevola-time-clock.stagingurl.tk/api/company/download?file_name=${String(
                //                 //                 each.document
                //                 //             )}`,
                //                 //             {
                //                 //                 headers: {
                //                 //                     authorization: `Bearer ${token}`,
                //                 //                 },
                //                 //             }
                //                 //         ).then((res) => {
                //                 //             type = res.type
                //                 //             res.blob()
                //                 //         })

                //                 //     const myFile = new File([res()], `${filename}_${index}`, {
                //                 //         type: type,
                //                 //     })
                //                 //     myFile.id = each.id
                //                 //     return myFile
                //                 // })
                //               }  

                state.vendorDoc = data
                state.vendorDocLoading = false
            } else {
                state.vendorDoc = []
                state.vendorDocLoading = false
            }
        },

        setEmployeeDocManually: (state, actions) => {
            const { file, id } = actions.payload
            if (id) {
                state.employeeDoc = state?.employeeDoc?.filter((item) => item.id !== id)
            } else {
                state.employeeDoc = [...state.employeeDoc, file]
            }
        },
        setVendorDocManually: (state, actions) => {
            const { file, id, type, name } = actions.payload
            if (type === 'docs') {
                state[name] = [...state[name], file]
            } else if (type === "delete") {
                state[name] = state[name].filter((item) => item.id !== id)
            }
        },
        setCustomerDocManually: (state, actions) => {
            const { file, id } = actions.payload
            if (id) {
                state.customerDoc = state?.customerDoc?.filter((item) => item.id !== id)
            } else {
                state.customerDoc = [...state.customerDoc, file]
            }
        },
        setvendorBankDocuments: (state, actions) => {
            const { array, token } = actions.payload
            if (array.length >= 1) {
                state.vendorDocLoading = true
                // const data = array.map((each, index) => {
                //     let length = each.document.split(".")[0].split("/").length - 1
                //     let filename = each.document.split(".")[0].split("/")[length]
                //     let type = ""
                //     const res = async () =>
                //         await fetch(
                //             `https://tevola-time-clock.stagingurl.tk/api/company/download?file_name=${String(
                //                 each.document
                //             )}`,
                //             {
                //                 headers: {
                //                     authorization: `Bearer ${token}`,
                //                 },
                //             }
                //         ).then((res) => {
                //             type = res.type
                //             res.blob()
                //         })

                //     const myFile = new File([res()], `${filename}_${index}`, {
                //         type: type,
                //     })
                //     myFile.id = each.id
                //     return myFile
                // })
                const data = array.map((doc) => {
                    return {
                        name: doc.document,
                        id: doc.id,
                        uid: doc.id,
                        url: `${process.env.REACT_APP_IMG_URL}${doc.document}`,
                        status: "done"
                    }
                })
                state.vendorBankDoc = data
                state.vendorDocLoading = false
            } else {
                state.vendorBankDoc = []
                state.vendorDocLoading = false
            }
        },
        setvendorAddDocuments: (state, actions) => {
            const { array, token } = actions.payload
            if (array.length >= 1) {
                state.vendorDocLoading = true
                const data = array.map((doc) => {
                    return {
                        name: doc.document,
                        id: doc.id,
                        uid: doc.id,
                        url: `${process.env.REACT_APP_IMG_URL}${doc.document}`,
                        status: "done"
                    }
                })
                // const data = array.map((each, index) => {
                //     let length = each.document.split(".")[0].split("/").length - 1
                //     let filename = each.document.split(".")[0].split("/")[length]
                //     let type = ""
                //     const res = async () =>
                //         await fetch(
                //             `https://tevola-time-clock.stagingurl.tk/api/company/download?file_name=${String(
                //                 each.document
                //             )}`,
                //             {
                //                 headers: {
                //                     authorization: `Bearer ${token}`,
                //                 },
                //             }
                //         ).then((res) => {
                //             type = res.type
                //             res.blob()
                //         })

                //     const myFile = new File([res()], `${filename}_${index}`, {
                //         type: type,
                //     })
                //     myFile.id = each.id
                //     return myFile
                // })
                state.vendorAddDoc = data
                state.vendorDocLoading = false
            } else {
                state.vendorAddDoc = []
                state.vendorDocLoading = false
            }
        },
    },
})

export const {
    setEmployeeDocuments,
    setCustomerDocuments,
    setvendorDocuments,
    setvendorBankDocuments,
    setvendorAddDocuments,
    setEmployeeDocManually,
    setVendorDocManually,
    setCustomerDocManually,
} = documentSlice.actions

export default documentSlice.reducer
