import { message } from "antd";
import { setEmployeeDocuments } from "../../slice/documentSlice";
import { mainApi } from "../mainApi";

//Auth Api
export const employeeApi = mainApi.injectEndpoints({
	endpoints: (builder) => ({
		//create checkIn
		addEditEmployee: builder.mutation({
			query: ({ id, formData, key = 0 }) => ({
				url: id ? `company/employee/${id}/${key}` : `company/employee`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Employee", "CheckIn"],
		}),
		createCheckIn: builder.query({
			query: () => `company/employee/create`,
			providesTags: ["CheckIn"],
		}),
		//list employee
		listEmployee: builder.query({
			query: (params) =>
				`company/employee?${params}`,
			providesTags: ["Employee"],
		}),

		//View Employee
		viewEmployee: builder.query({
			query: (employeeId) => `company/employee/${employeeId}`,
			providesTags: ["Employee"],
		}),
		//Add Employee
		addEmployee: builder.mutation({
			query: (formData) => ({
				url: "company/employee",
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Employee", "CheckIn"],
		}),
		dashbaord: builder.query({
			query: () => `/company/employee/entry/active`,
		}),
		//Edit Employee
		editEmployee: builder.mutation({
			query: ({ id, formData, key }) => ({
				url: `company/employee/${id}/${key}`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Employee", "CheckIn"],
		}),
		//deleteEmployee
		deleteEmployee: builder.mutation({
			query: (id) => ({
				url: `company/employee/${id}`,
				method: "PATCH",
			}),
			invalidatesTags: ["Employee"],
		}),
		//Essential
		essential: builder.query({
			query: (key) =>
				(key === "main-timesheet" ) ? `company/employee/agent/dropdown-list` :
				( key === "emp-report") ? `company/employee/agent/dropdown-list?type=report` :
				`company/essential-list?select=role,business_type,department,position,employee`,

			providesTags: ["Essential"],
		}),
		
		//status Change
		statusChange: builder.mutation({
			query: ({ formData, id }) => {
				return {
					url: `company/employee-status/${id}`,
					method: "PATCH",
					body: formData,
				};
			},
			invalidatesTags: ["Employee"],
		}),
		employeeYearData: builder.query({
			query: ({ id, year }) => {
				return {
					url: `company/employee/attendance/list?employee_id=${id}&year=${year}`,
				};
			},
			providesTags: ["Employee"],
		}),
		//Download
		downloadMailPrint: builder.query({
			query: (file) => ({
				url: file && `company/download?file_name=${String(file)}`,
			}),
		}),
		//Employee entry PDF download
		employeePdfDownload: builder.mutation({
			query: (params) => {
				return {
					url: `company/employee/entry/download/${params.id}`,
					method: "POST",
					body: {
						month: params.month,
						year: params.year,
						search: params.id,
					},
					responseHandler: async (res) => {
						if (res.status === 200) {
							return await res.blob();
						}
						//  else if (res?.status === 204) {
						//     message.info("No Data")
						// }
						else {
							return await res.text();
						}
					},
				};
			},
		}),
		deleteDocument: builder.mutation({
			query: (id) => ({
				url: `company/employee-document/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Employee"],
		}),
		uploadAndDeleteLogo: builder.mutation({
			query: ({ type, id, formData }) => {
				return {
					url: `company/employee/logo/${type}/${id}`,
					method: "POST",
					body: formData,
				};
			},
			invalidatesTags: ["Employee"],
		}),
		//Atttendence list
		employeeAttendenceList: builder.query({
			query: () => `company/employee/attendance/list`,
		}),

		//Employee bonus list
		employeeBonus: builder.query({
			query: ( id ) => `company/employee-bonus?id=${id}`,
			providesTags: ["Employee"],
		}),

		//Add employee bonus 
		addEmployeeBonus: builder.mutation({
			query: ( formData ) => ({
				url: "company/employee-bonus",
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Employee"],
		}),

		//Delete employee Bonus
		deleteEmployeeBonus: builder.mutation({
			query: ( id ) => ({
				url: `company/employee-bonus/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Employee"],
		}),

		// Employee pdf and excel download
		employeeAllDownload: builder.mutation({
			query: (params) => {
				return {
					url: "/company/employee/list-download",
					method: "POST",
					body: {
						// search: params.search ?? "",
						// from_date: params.from_date ?? "",
						// to_date: params.to_date ?? "",
						// employee: params.employee ?? "",
						agent: params.agent ?? "",
						type: params.type ?? "",
						status: params.status ?? "",
						file_type: params.file_type ?? "",
						tb_columns: params.tb_columns ?? ""
					},
					responseHandler: async (res) => {
						if (res.status === 200) {
							return await res.blob();
						} else if (res?.status === 204) {
							message.info("No Data");
						} else {
							return await res.text();
						}
					},
				};
			},
		}),

	}),
});

export const {
	useAddEditEmployeeMutation,
	useListEmployeeQuery,
	useCreateCheckInQuery,
	useViewEmployeeQuery,
	useAddEmployeeMutation,
	useEditEmployeeMutation,
	useEssentialQuery,
	useDeleteEmployeeMutation,
	useDownloadMailPrintQuery,
	useEmployeeYearDataQuery,
	useDashbaordQuery,
	useStatusChangeMutation,
	useEmployeePdfDownloadMutation,
	useEmployeeAttendenceListQuery,
	useDeleteDocumentMutation,
	useUploadAndDeleteLogoMutation,
	useEmployeeBonusQuery,
	useAddEmployeeBonusMutation,
	useDeleteEmployeeBonusMutation,
	useEmployeeAllDownloadMutation

	// useSingleEmployeeTimesheetQuery,
} = employeeApi;
