import { Button, Col, Form, Input, Row, message } from "antd"
import React from "react"
import { CheckOutlined } from '@ant-design/icons';

const ChangePassword = ({ handleChangePassword, loading, role }) => {
    const [form] = Form.useForm()
    return (
        <Form
            form={form}
            labelAlign="left"
            labelWrap
            wrapperCol={{
                span: role === 's-admin' ? 18 : 8,
            }}
            labelCol={{
                span: role === 's-admin' ? 10 : 8,
            }}
            layout={"horizontal"}
            onFinish={handleChangePassword}>
            <Row style={{ paddingLeft: '30px' }}>
                <Col span={24}>
                    <Form.Item
                        label={"Old password"}
                        name="old_password"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your Old Password",
                            },
                        ]}>
                        <Input.Password
                            onPaste={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            placeholder="Old password"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={"New password"}
                        name="new_password"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your New Password",
                            },
                        ]}>
                        <Input.Password
                            onPaste={() => {
                                return false
                            }}
                            placeholder="New password"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={"Confirm password"}
                        name="new_confirm_password"

                        rules={[
                            {
                                required: true,
                                message: "Please enter your Confirm Password",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("new_password") === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "The Confirm passwords that you entered does not match!"
                                        )
                                    )
                                },
                            }),
                        ]}>
                        <Input.Password
                            onPaste={() => {
                                return false
                            }}
                            placeholder="Confirm password"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {/* <Row>
                        <Col span={4} offset={role === 's-admin' ? 6 : 4}>
                            <Button type="primary" >
                                Submit
                            </Button>
                        </Col>
                    </Row> */}
                </Col>
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "10px",
                        gap: 20,
                        marginTop: '20px'
                    }}>
                    {/* <Button htmlType="reset" className="cancelBtn">
                        Cancel
                    </Button> */}
                    <Button loading={loading} type="primary" onClick={form.submit} className="saveButtonPrimary"
                        icon={<CheckOutlined />}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ChangePassword
