import { createSlice } from "@reduxjs/toolkit"
import { message } from "antd"

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        admin_token: null,
        super_admin_token: null,
        role: null,
        error: ''
    },
    reducers: {
        setUser: (state, actions) => {
            const { token, role, admin_token, super_admin_token, user } = actions.payload
            state.user = user ?? state.user
            state.role = role ?? state.role
            state.token = token ?? state.token
            state.admin_token = admin_token ? admin_token : state.admin_token
            state.super_admin_token = super_admin_token ? super_admin_token : state.super_admin_token
        },
        setError: (state, actions) => {
            message.error(actions?.payload)
        },
        logOut: (state, actions) => {
            state.user = null
            state.token = null
            state.role = state.role === actions.payload
            localStorage.clear()
        },
    },
})

export const { setUser, logOut, setError } = authSlice.actions

export default authSlice.reducer
