import { message } from 'antd'

export default function errorMessage(errors) {

    for (const key in errors) {

        // if (Object.prototype.hasOwnProperty.call(errors, key)) {
            const errorMsg = errors[key];
        
            message.error(errorMsg);
        // break;
        // }

    }
}
