import { mainApi } from "../mainApi";

export const adminSettingApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        //Login
        profileAdminUpdate: builder.mutation({
            query: (formData) => ({
                url: `/company/profile/update`,
                method: "POST",
                body: formData
            }),
            invalidatesTags: ['Profile']
        }),
        //Get profile
        adminGetMe: builder.query({
            query: () => `/company/me`,
            providesTags: ["Profile"]
        }),
        //logo Update
        adminLogoUpdate: builder.mutation({
            query: ({ url, formData }) => ({
                url: `/company/logo/${url}`,
                method: "POST",
                body: formData
            }),
            invalidatesTags: ["Profile"]
        }),

    })
}
)

export const {
    useProfileAdminUpdateMutation,
    useAdminGetMeQuery,
    useAdminLogoUpdateMutation
} = adminSettingApi