import { mainApi } from "../mainApi"

export const notificationApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        notificationList: builder.query({
            query: () => '/company/notification',
            providesTags: ["Customer"]

        }), 
    }),
})

export const {
    useNotificationListQuery,
} = notificationApi