import React, { useState } from "react"
import { Breadcrumb, Col, Layout, message, Row, theme, Typography } from "antd"
import { Button, Checkbox, Form, Input } from "antd"
import "../login.css"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAdminLoginApiMutation, useForgetPasswordMutation } from "../../../features/api/adminApi/admin_auth.js"
import { setUser } from "../../../features/slice/authSlice.js"
import errorMessage from "../../errorMessage/ErrorMessage"
import circleInsideLogo from "../../../assets/circle-inside-logo.png"
import "./adminlogin.css"


const { Content } = Layout

const ForgetPassword = () => {

    //Hooks
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [passwordShow, setPasswordShow] = useState(false)

    //Admin Login Api
    const [forgetPassword, { isLoading }] = useForgetPasswordMutation()

    const handleForgetPassword = (values) => {
        forgetPassword(values)
            .unwrap()
            .then((res) => {
                message.success(res.message)
                form.setFieldsValue({
                    email: ""
                })
            }).catch(err => {
                // form.setFieldsValue({
                //     email: ""
                // })
                errorMessage(err?.data?.error)
            })
    }

    return (
        <Layout className="layout">
            <Row justify={"center"}>
                {/* <Col sapn={24}>
                        <div className="logo">
                            <img src={circleInsideLogo} alt="" />
                        </div>
                    </Col> */}
                <Col span={24} className="title">
                    <Typography.Title style={{
                        textAlign: "center"
                    }}>
                        Forget Password
                    </Typography.Title>
                </Col>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={handleForgetPassword}>
                    <Row justify={"center"}>
                        <Col span={24}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Please enter email id"
                                }]} name={"email"} label={"Email ID"}>
                                <Input placeholder="Email ID" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button
                                loading={isLoading}
                                style={{
                                    width: "120px",
                                }}
                                type="primary"
                                htmlType="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
            {/* </div> */}
        </Layout >
    )
}

export default ForgetPassword
