import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { message } from "antd";
import { logOut, setError } from "../slice/authSlice";

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_MAIN_URL,
	prepareHeaders: (headers, { getState }) => {
		// headers.set("ngrok-skip-browser-warning", "69420")
		const user = getState().auth;
		if (user?.role === "SuperAdmin") {
			headers.set("authorization", `Bearer ${user?.super_admin_token}`);
			return headers;
		} else if (user?.role === "Admin") {
			headers.set("authorization", `Bearer ${user?.admin_token}`);
			return headers;
		} else {
			headers.set("authorization", `Bearer ${user?.token}`);
			return headers;
		}
	},
	keepUnuseDataFor: 5,
	// cache : "no-cache"
});

const baseQueryWithReauth = async (args, api, extraOptions = {}) => {
	const result = await baseQuery(args, api, extraOptions);
	if (result?.meta?.response) {
		if (result?.error?.status === 401) {
			// message.error("Please Login")
			const path = window.location.pathname.split("/")[1];
			if (path === "super-admin") {
				window.location.href = `${window.location.origin}/super-admin/login`;
			} else {
				window.location.href = `${window.location.origin}/admin/login`;
			}
		}
		// if (result?.error?.originalStatus === 500) {
		//     message.error("Network Error")
		// }
		return result;
	} else {
		message.error("Network Error");
	}

	return result;

	// return result
};

// const basequeryWithReauth = async (args,api, extraOptions)=>{
//     let result = await baseQuery(args, api, extraOptions)
//     if(result?.error?.status === 403){
//         const refreshResult = await baseQuery('auth/refresh', api, extraOptions)
//         if(refreshResult?.data){
//             api.dispatch(setUser({
//                 ...data,
//                 token : refreshResult?.data
//             }))
//             result = await baseQuery(args, api, extraOptions)
//         }else{
//             if(refreshResult?.error?.status === 403){
//                 refreshResult?.error?.data?.message = "Your Login has expired."
//             }
//         }
//     }
// }

export const mainApi = createApi({
	reducerPath: "mainApi",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["Employee", "Vendor", "Customer", "CheckIn", "Essential", "Profile", "Timesheet", "Master"],
	keepUnusedDataFor: 5,
	endpoints: (builder) => ({}),
});
