import { Button, Col, Layout, Row, Select, Spin, Table, Typography } from "antd"
import React, { useEffect, useState } from "react"
import MainLayout from "../../components/main_layout/MainLayout"
import roundEmpImage from "../../assets/round-emp-image.png"
import roundTimeImage from "../../assets/round-time-image.png"
import roundVendorImage from "../../assets/round-vendor-image.png"
import roundReportImage from "../../assets/round-report-image.png"
import dashbaordBanner from "../../assets/dashboard.png"
import circleInsideLogo from "../../assets/circle-inside-logo.png"
import Banner from "../../assets/dashboard_banner.png"
import "./dashboard.css"
import { Link, useLocation } from "react-router-dom"
import { useTimesheetListQuery } from "../../features/api/adminApi/timesheet_api"
import { useSelector } from "react-redux"
import { useDashbaordQuery } from "../../features/api/adminApi/employee_api"
import { useListCustomerQuery } from "../../features/api/superadminApi/customer_api"
import { suppressDeprecationWarnings } from "moment/moment"

const Dashboard = () => {
    const location = useLocation()
    const role = useSelector((state) => state.auth.role)
    const user = useSelector((state) => state.auth.user)
    const [activeEmployee, setActiveEmployee] = useState([])
    const [activeCustomer, setActiveCustomer] = useState([])
    const person = location.pathname === "/super-admin/dashboard" ? "super-admin" : "admin"

    //activeEmployee
    const params = {
        perPage: 5,
        page: 1
    }
    const { data: activeCustomerApi, isFetching: customerFetching } = useListCustomerQuery(params)

    const { data: activeEmployeeApi, isFetching: employeeFetching, isLoading } = useDashbaordQuery()

    const Columns = [
        {
            title: person === "super-admin" ? "Customer ID" : "Employee ID",
            dataIndex: person === "super-admin" ? "reference_no" : "check_in_id",
            render: (_, record) => (
                person === "super-admin"
                 ? 
                <Link to={`/super-admin/customer/view/${record.id}`}>{record?.reference_no}</Link> 
                :
                <Link to={`/admin/employees/view/${record?.employee_id}`}>{record?.employee_reference}</Link>
            ),
        },
        {
            title: person === "super-admin" ? "Customer Name" : "Employee Name",
            dataIndex: person === "super-admin" ? "name" : "employee_name",
            render: (_, record) => (
                person === "super-admin"
                 ? 
                <Link to={`/super-admin/customer/view/${record.id}`}>{record?.name}</Link> 
                :
                <Link to={`/admin/employees/view/${record?.employee_id}`}>{record?.employee_name}</Link>
            ),
        },
        {
            title: "Status",
            dataIndex: "check_in_status",
            render: (_, record) => person !== "super-admin" ? <span className="check_in_status">{record.check_in_status}</span> : <span className="check_in_status">{record.status === 1 && "Active"}</span>
        },
    ]

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    useEffect(() => {
        if (activeEmployeeApi !== undefined) {
            const filter = activeEmployeeApi?.data.filter((item) =>
                item.status === "Check IN" && item
            )
            setActiveEmployee(filter)
        }
    }, [activeEmployeeApi])

    useEffect(() => {
        if (activeCustomerApi !== undefined) {
            const filter = activeCustomerApi?.data.data.filter((item) =>
                item.status && item
            )
            setActiveCustomer(filter)
        }
    }, [activeCustomerApi])

    return (
        <Spin spinning={isLoading}>
            <MainLayout title={person === "super-admin" ? `Welcome ${user.first_name}` : `Welcome ${user?.name ?? "Admin"}`}
                upload={[]} breadcrumbs={[]} button={[]}
                banner={screenSize.dynamicWidth >= 1200 && true}>
                <section className="dashboard_wrapper">
                    <Row gutter={[20, 20]} justify="space-around" align={"middle"} style={{
                        marginLeft: 0,
                        marginRight : 0
                    }}>
                        <Col span={14} className="dashboard">
                            <div className="five">
                                <img src={circleInsideLogo} alt="" />
                            </div>
                            <Link to={person === "super-admin" ? "/super-admin/customers" : "/admin/employees"} className="one">
                                <img src={roundEmpImage} alt="" />
                                <p>{person === "super-admin" ? "Customers" : "Employees"}</p>
                            </Link>
                            <Link to={person === "super-admin" ? "" : "/admin/timesheets"} className="two">
                                <img src={roundTimeImage} alt="" />
                                <p>{person === "super-admin" ? "Notifications" : "Timesheet"}</p>
                            </Link>
                            <Link to={person === "super-admin" ? "/super-admin/settings" : "/admin/vendors"} className="three">
                                <img src={roundVendorImage} alt="" />
                                <p>{person === "super-admin" ? "Settings" : "Vendors"}</p>
                            </Link>
                            <Link to={person === "super-admin" ? "" : "/admin/reports"} className="four">
                                <img src={roundReportImage} alt="" />
                                <p>Reports</p>
                            </Link>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                            <Row
                                justify={"space-between"}
                                className="dashboard_active_bg"
                                gutter={[10, 20]}>
                                <Col span={24}>
                                    <Row
                                        // justify={"center"}
                                        // align="middle"
                                        gutter={[20, 20]}
                                        className="dashboard_active_table">
                                        <Col span={24}>
                                            <Row align="middle">
                                                {person === "super-admin" ? (<Col span={16} className="tableHead">Customers</Col>) :
                                                    (<Col span={16} className="tableHead">Active Employees</Col>)}
                                                <Col span={8} className="dashboard_active_table_bg">
                                                    <Link to={person === "super-admin" ? `/super-admin/customers` : `/admin/employees`}>
                                                        <p>More Details</p>
                                                    </Link>

                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Table
                                                columns={Columns}
                                                dataSource={person === "super-admin" ? activeCustomer : activeEmployee}
                                                pagination={false}
                                                scroll={{
                                                    y: 150,
                                                }}></Table>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24}>
                                    <img
                                        className="dashboard_banner"
                                        src={dashbaordBanner}
                                        alt=""
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </MainLayout>
        </Spin>
    )
}

export default Dashboard
