import { Breadcrumb } from "antd"
import React from "react"
import Arrow from "../../assets/breadcrumbArrow.PNG"

const BreadCrumbs = ({ breadcrumbs }) => {
    return (
        <Breadcrumb separator={
            <img src={Arrow} style={{
                width: '20px',
                position: 'relative',
                top: '3px'
            }} />
        }>
            {breadcrumbs?.map((item, index) => {
                return (
                    <Breadcrumb.Item key={index} href={item.href}>
                        <span className={item?.href === undefined ? "breadcrumb_active" : ""}>
                            {item.title}
                        </span>
                    </Breadcrumb.Item>
                )
            })}
        </Breadcrumb>
    )
}

export default BreadCrumbs
