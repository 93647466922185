import { setError } from "../../slice/authSlice"
import { mainApi } from "../mainApi"

//Auth Api
export const adminAuth = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        //Login
        adminLoginApi: builder.mutation({
            query: (formData) => ({
                url: `/company/login`,
                method: "POST",
                body: formData
            }),
            invalidatesTags: ['CheckIn', 'Customer', 'Employee', 'Profile', 'Timesheet', 'Vendor']
        }),

        //Logout
        logoutAdmin: builder.mutation({
            query: ({ url }) => ({
                url: `${url}/logout`,
                method: "POST",
                // body: formData
            }),
            invalidatesTags: [],
            // async onQueryStarted(id, { dispatch, queryFulfilled }) {
            //     // `onStart` side-effect
            //     try {
            //         // await queryFulfilled
            //         // `onSuccess` side-effect
            //         dispatch(mainApi.util.resetApiState())
            //     } catch (err) {
            //         // `onError` side-effect
            //         // dispatch(setError("Network Error!"))
            //     }
            // }
        }),
        forgetPassword: builder.mutation({
            query: (formData) => ({
                url: `company/password/forgot`,
                method: "POST",
                body: formData
            })
        }),
        resetPassword: builder.mutation({
            query: (formData) => ({
                url: `company/password/reset`,
                method: "POST",
                body: formData
            })
        }),
    }),
})

export const {
    useAdminLoginApiMutation,
    useLogoutAdminMutation,
    useResetPasswordMutation,
    useForgetPasswordMutation
} = adminAuth
