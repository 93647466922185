import {
	Avatar,
	Col,
	message,
	Modal,
	Row,
	Tabs,
	Upload,
	Button,
	Typography,
	Spin,
} from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { CiUser } from "react-icons/ci/";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../components/main_layout/MainLayout";
import BankingInformation from "../../components/vendor_sections/banking_infomation";
import EmployeeDetails from "../../components/vendor_sections/employee_details";
import VendorAddtionInformation from "../../components/vendor_sections/vendor_addition_informations";
import VendorInformation from "../../components/vendor_sections/vendor_information";
import CustomerModal from "../../components/custommodal/CustomerModal";
import {
	useDeleteVendorDocumentMutation,
	useEditVendorMutation,
	useVendorLogoUpdateAndDeleteMutation,
	useViewVendorQuery,
} from "../../features/api/adminApi/vendor_api";
import errorMessage from "../../global/errorMessage/ErrorMessage";
import "./vendor.css";
import { profileUpload, urlToFile } from "../../global/helpers";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

const ViewEmployee = () => {
	//Hooks
	const navigate = useNavigate();
	const { vendorId } = useParams();

	//states
	const [key, setKey] = useState(1);
	const [vendorDocs, setVendorDocs] = useState([]);
	const [vendorBankDocs, setVendorBankDocs] = useState([]);
	const [vendorAddDocs, setVendorAddDocs] = useState([]);
	// const [vendor, setVendor] = useState([]);
	const imageRef = useRef();
	const [image, setImage] = useState("");
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const [deleteShow, setDeleteShow] = useState(false);
	const [removeDoc, setRemoveDoc] = useState({});
	const [removeFile, setRemoveFile] = useState("");
	const [deleteType, setDeleteType] = useState("");


	const [deleteVendorDouments, { isLoading: vendorDocLoading }] =
		useDeleteVendorDocumentMutation();

	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
	};

	const handleDeleteLogo = async(file) => {
		try {
			const formData = new FormData();
			if (file !== undefined || file !== null) {
				formData.append("logo", file);
			} else {
				formData.append("logo", null);
			}
			const result = await logoUpdateAndDelete({
				type: "delete",
				id: vendorId,
				formData,
			}).unwrap();
			message.success(result.message);
			setFileList([]);
			setDeleteShow(false)
			// onSuccess("Ok");
		}catch (error) {
			errorMessage(error.data.error);
			// onError("error");
		}
	}
	const handleDeleteDocument = async (file) => {
		if (file?.id) {
			try {
				const params = {
					id: file?.id,
					path: file?.path,
				};
				const result = await deleteVendorDouments(params).unwrap();
				message.success(result.message);
				setDeleteShow(false);
			} catch (error) {
				errorMessage(error?.data?.error);
			}
		} else {
			if (file?.path === "vendor-document") {
				setVendorDocs((state) => {
					return state.filter((item) => {
						return item.name !== file?.name;
					});
				});
				setDeleteShow(false);
				message.success("Vendor document Deleted");
			} else if (file?.path === "vendor-bank-document") {
				vendorBankDocs((state) => {
					return state.filter((item) => {
						return item.name !== file.name;
					});
				});
				setDeleteShow(false);
				message.success("Vendor bank document Deleted");
			} else if (file?.path === "vendor-addtional-document") {
				vendorAddDocs((state) => {
					return state.filter((item) => {
						return item.name !== file.name;
					});
				});
				message.success("Vendor additional document Deleted");
			}
			setDeleteShow(false);
		}
	};

	const breadcrumbs = [
		{
			title: "Dashboard",
			href: "/admin/dashboard",
		},
		{
			title: "Our Vendors",
			href: "/admin/employee",
		},
		{
			title: "Vendor Details",
			// href: location.pathname,
		},
	];

	//Api
	const {
		data: viewVendor,
		refetch,
		isLoading: vendorLoading,
		isFetching: vendorFetching,
		isSuccess: vendorSuccess,
	} = useViewVendorQuery(vendorId);

	const [editVendor, { isLoading: editLoading }] = useEditVendorMutation();
	const [logoUpdateAndDelete] = useVendorLogoUpdateAndDeleteMutation();

	const handleVendorSave = (values) => {
		if (values.establishment_date) {
			values.establishment_date = values.establishment_date
				? dayjs(values.establishment_date).format("YYYY-MM-DD")
				: "";
		}
		let data = {};
		if (values["profile_image"] === "" || values["profile_image"] === null) {
			const { profile_image, document, bank_document, addtional_document, ...others } =
				values;
			data = others;
		} else {
			const { document, bank_document, addtional_document, ...others } = values;
			data = others;
		}

		const formData = new FormData();
		if (vendorDocs.length >= 1) {
			_.forEach(vendorDocs, (file, index) => {
				if (!file.id) {
					formData.append(`document[${index}]`, file);
				}
			});
		}
		if (vendorBankDocs.length >= 1) {
			_.forEach(vendorBankDocs, (file, index) => {
				if (!file.id) {
					formData.append(`bank_document[${index}]`, file);
				}
			});
		}
		if (vendorAddDocs.length >= 1) {
			_.forEach(vendorAddDocs, (file, index) => {
				if (!file.id) {
					formData.append(`addtional_document[${index}]`, file);
				}
			});
		}
		for (const item in data) {
			if (item !== "document" || item !== "bank_document" || item !== "addtional_document") {
				if (_.isNull(data[item]) || _.isUndefined(data[item])) {
				} else {
					formData.append(item, data[item]);
				}
			}
		}
		const param = {
			id: vendorId,
			formData: formData,
			key: key === 4 ? 3 : key,
		};
		editVendor(param)
			.unwrap()
			.then((res) => {
				message.success("Vendor updated successfully");
				// navigate("/admin/vendors");
			})
			.catch((err) => errorMessage(err?.data?.error));
	};
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
	};
	const handleCancel = () => setPreviewOpen(false);
	const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

	useEffect(() => {
		if (viewVendor) {
			setImage(
				viewVendor?.data?.profile_image
					? `${process.env.REACT_APP_IMG_URL}/${viewVendor?.data?.profile_image}`
					: ""
			);
			const url = viewVendor?.data?.profile_image
				? `${process.env.REACT_APP_IMG_URL}/${viewVendor?.data?.profile_image}`
				: "";
			if (url) {
				setFileList([
					{
						uid: url,
						status: "done",
						url,
						name: url.substring(url.lastIndexOf("/") + 1),
					},
				]);
			}
			let docs = [];
			let addDocs = [];
			let bankDocs = [];
			if (viewVendor?.data?.documents?.length >= 1) {
				viewVendor?.data?.documents?.forEach((doc) => {
					docs.push(urlToFile(doc));
				});
			}
			if (viewVendor?.data?.addtionaldocuments?.length >= 1) {
				viewVendor?.data?.addtionaldocuments?.forEach((doc) => {
					addDocs.push(urlToFile(doc));
				});
			}
			if (viewVendor?.data?.bankdocuments?.length >= 1) {
				viewVendor?.data?.bankdocuments?.forEach((doc) => {
					bankDocs.push(urlToFile(doc));
				});
			}
			setVendorDocs(docs);
			setVendorAddDocs(addDocs);
			setVendorBankDocs(bankDocs);
		}
	}, [viewVendor]);

	useEffect(() => {
		if (key !== 3) {
			refetch(vendorId);
		}
	}, [key, refetch, vendorId]);

	useEffect(() => {
		window.addEventListener("resize", setDimension);

		return () => {
			window.removeEventListener("resize", setDimension);
		};
	}, [screenSize]);

	return (
		<>
			<MainLayout title={"Vendor Details"} upload={[]} breadcrumbs={breadcrumbs} button={[]}>
				<Row className="add_outlet" style={{ paddingBlockStart: 30 }}>
					<Col xs={24} sm={24} md={24} lg={24} xl={5}>
						<Row
							gutter={[10, 10]}
							justify={"center"}
							style={
								{
									// paddingTop: "20px",
								}
							}>
							<Col
								span={24}
								style={{
									marginLeft: screenSize.dynamicWidth <= 1200 && "30px",
									textAlign: "center",
								}}>
								{/* <Avatar
                                    className="user_icon"
                                    icon={
                                        <Image
                                            width={100}
                                            height={100}
                                            shape="circle"
                                            src={image}
                                        />
                                    }
                                /> */}
								<>
								<div className="upload-center">
									<Upload
										customRequest={async (options) => {
											const { file, onSuccess, onError } = options;
											try {
												const formData = new FormData();
												formData.append("logo", file);
												const result = await logoUpdateAndDelete({
													type: "update",
													id: vendorId,
													formData,
												}).unwrap();
												message.success(result.message);
												onSuccess("Ok");
											} catch (error) {
												errorMessage(error?.data?.error);
												onError("error");
											}
										}}
										beforeUpload={profileUpload}
										accept=".jpeg,.jpg,.png"
										// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
										listType="picture-circle"
										maxCount={1}
										fileList={fileList}
										onRemove={
												// async (options) => {
												// 	const { file, onSuccess, onError } = options;
												// 	try {
												// 		const formData = new FormData();
												// 		if (file !== undefined || file !== null) {
												// 			formData.append("logo", file);
												// 		} else {
												// 			formData.append("logo", null);
												// 		}
												// 		const result = await logoUpdateAndDelete({
												// 			type: "delete",
												// 			id: vendorId,
												// 			formData,
												// 		}).unwrap();
												// 		message.success(result.message);
												// 		setFileList([]);
												// 		onSuccess("Ok");
												// 	} catch (error) {
												// 		errorMessage(error.data.error);
												// 		onError("error");
												// 	}
												// }
												(file) =>{
													setRemoveFile(file)
													setDeleteType("logo")
													setDeleteShow(true)
													return false
												}
											}
										onPreview={handlePreview}
										onChange={handleChange}>
										{fileList.length < 1 ? (
											<>
												<Button ref={imageRef} className="d-none"></Button>
												<Avatar
													icon={<CiUser />}
													className="layout_top_right_avatar"
												/>{" "}
											</>
										) : null}
									</Upload>
									{/* {fileList.length < 1 ? ( */}
									<Typography.Text
										onClick={() => {
											imageRef.current.click();
										}}
										// className="user_icon_name"
										style={{
											cursor: "pointer",
											// fontSize: "17px",
											// fontWeight: 500,
											textDecoration: "underline",
											
										}}>
										Upload Logo
									</Typography.Text>
									{/* )  */}
									{/* : null} */}

									<Modal
										open={previewOpen}
										title={previewTitle}
										footer={null}
										onCancel={handleCancel}>
										<img
											alt="example"
											style={{
												width: "100%",
											}}
											src={image}
										/>
									</Modal>
								</div>
								</>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={19}>
								<Tabs
									defaultActiveKey={1}
									activeKey={key}
									onChange={(key) => {
										setKey(key);
									}}
									tabPosition={"left"}
									items={[
										{
											label: "Vendor Information",
											key: 1,
										},
										{
											label: "Banking Information",
											key: 2,
										},
										{
											label: "Employee Details",
											key: 3,
										},
										{
											label: "Additional Information",
											key: 4,
										},
									]}></Tabs>
							</Col>
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={19}>
						<Spin
							spinning={
								vendorLoading === true || vendorFetching === true ? true : false
							}>
							{vendorSuccess && (
								<>
									{key === 1 ? (
										<VendorInformation
											setDeleteShow={setDeleteShow}
											setRemoveDoc={setRemoveDoc}
											deleteShow={deleteShow}
											navigate={navigate}
											vendorId={vendorId}
											viewEmployee={viewVendor?.data}
											editLoading={editLoading}
											handleVendorSave={handleVendorSave}
											vendorLoading={vendorLoading}
											vendorFetching={vendorFetching}
											isSucess={vendorSuccess}
											setVendorDocs={setVendorDocs}
											vendorDocs={vendorDocs}
										/>
									) : key === 2 ? (
										<BankingInformation
											setDeleteShow={setDeleteShow}
											setRemoveDoc={setRemoveDoc}
											deleteShow={deleteShow}
											navigate={navigate}
											vendorId={vendorId}
											viewEmployee={viewVendor?.data}
											editLoading={editLoading}
											handleVendorSave={handleVendorSave}
											vendorLoading={vendorLoading}
											vendorFetching={vendorFetching}
											isSucess={vendorSuccess}
											vendorBankDocs={vendorBankDocs}
											setVendorBankDocs={setVendorBankDocs}
										/>
									) : key === 3 ? (
										<EmployeeDetails vendorId={vendorId} navigate={navigate} />
									) : (
										<VendorAddtionInformation
											viewEmployee={viewVendor?.data}
											editLoading={editLoading}
											vendorLoading={vendorLoading}
											vendorFetching={vendorFetching}
											vendorAddDocs={vendorAddDocs}
											handleVendorSave={handleVendorSave}
											isSucess={vendorSuccess}
											vendorId={vendorId}
											navigate={navigate}
											setDeleteShow={setDeleteShow}
											setRemoveDoc={setRemoveDoc}
											deleteShow={deleteShow}
											setVendorAddDocs={setVendorAddDocs}
										/>
									)}
								</>
							)}
						</Spin>
						{deleteShow ? (
							<CustomerModal
								handleFinish={() => deleteType === "logo" ? handleDeleteLogo(removeFile): handleDeleteDocument(removeDoc)}
								title={deleteType === "logo" ? "Delete Vendor Logo" : "Delete Vendor Document"}
								setIsModalOpen={setDeleteShow}
								isModalOpen={deleteShow}
								loading={vendorDocLoading}>
								{deleteType === "logo" ? "Are you sure to delete the logo" : "Are you sure to delete the document"}
							</CustomerModal>
						) : null}
					</Col>
					{/* <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <div className="user_icon user_icon1">
                            <Avatar
                                className="user_icon"
                                icon={<CiUser />}
                                src={
                                    vendor &&
                                    `${process.env.REACT_APP_IMG_URL}/${vendor?.data["profile_image"]}`
                                }
                            />
                        </div>
                        <Tabs
                            className="tabSectionRes"
                            defaultActiveKey={1}
                            activeKey={key}
                            onChange={(key) => {
                                setKey(key)
                            }}
                            tabPosition={screenSize.dynamicWidth <= 1200 ? "top" : "left"}
                            items={[
                                {
                                    label: "Vendor Information",
                                    key: 1,
                                },
                                {
                                    label: "Banking Information",
                                    key: 2,
                                },
                                {
                                    label: "Employee Details",
                                    key: 3,
                                },
                                {
                                    label: "Additional Information",
                                    key: 4,
                                },
                            ]}></Tabs>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                        {vendorSuccess && (
                            <>
                                {key === 1 ? (
                                    <VendorInformation
                                    navigate={navigate}
                                    vendorId={vendorId}
                                        viewEmployee={viewVendor?.data}
                                        editLoading={editLoading}
                                        handleVendorSave={handleVendorSave}
                                        vendorLoading={vendorLoading}
                                        vendorFetching={vendorFetching}
                                        isSucess={vendorSuccess}
                                        setVendorDocs={setVendorDocs}
                                        vendorDocs={vendorDocs}
                                    />
                                ) : key === 2 ? (
                                    <BankingInformation
                                    navigate={navigate}
                                    vendorId={vendorId}
                                        viewEmployee={viewVendor?.data}
                                        editLoading={editLoading}
                                        handleVendorSave={handleVendorSave}
                                        vendorLoading={vendorLoading}
                                        vendorFetching={vendorFetching}
                                        isSucess={vendorSuccess}
                                        vendorBankDocs={vendorBankDocs}
                                        setVendorBankDocs={setVendorBankDocs}
                                    />
                                ) : key === 3 ? (
                                    <EmployeeDetails vendorId={vendorId}  navigate={navigate}
                                     />
                                ) : (
                                    <VendorAddtionInformation
                                        viewEmployee={viewVendor?.data}
                                        editLoading={editLoading}
                                        vendorLoading={vendorLoading}
                                        vendorFetching={vendorFetching}
                                        vendorAddDocs={vendorAddDocs}
                                        handleVendorSave={handleVendorSave}
                                        isSucess={vendorSuccess}
                                        vendorId={vendorId}  navigate={navigate}
                                        setVendorAddDocs={setVendorAddDocs}
                                    />
                                )}
                            </>
                        )}
                    </Col> */}
				</Row>
			</MainLayout>
		</>
	);
};

export default ViewEmployee;
