import React, { useState } from 'react';
import { Typography, Col, Row, Form, Input, Button, Modal, message } from "antd"
import clearIcon from "../../assets/clear-icon.png"
import checkIcon from "../../assets/check-icon.png"

const KeyPadComponent = ({ onClick, onSubmit, loading }) => {
    return (
        <Row gutter={[0, 16]}>
            <Col span={8} className="numberBtn">
                <Button name="1" onClick={() => onClick(1)} type="primary" shape="circle">
                    1
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="2" onClick={() => onClick(2)} type="primary" shape="circle">
                    2
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="3" onClick={() => onClick(3)} type="primary" shape="circle">
                    3
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="4" onClick={() => onClick(4)} type="primary" shape="circle">
                    4
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="5" onClick={() => onClick(5)} type="primary" shape="circle">
                    5
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="6" onClick={() => onClick(6)} type="primary" shape="circle">
                    6
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="7" onClick={() => onClick(7)} type="primary" shape="circle">
                    7
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="8" onClick={() => onClick(8)} type="primary" shape="circle">
                    8
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="9" onClick={() => onClick(9)} type="primary" shape="circle">
                    9
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button style={{fontSize:'22px'}} name="CE" onClick={() => onClick('CE')} type="primary" shape="circle">
                    <img src={clearIcon} width='25' height='25' style={{ marginTop: '5px' }} />
                </Button>
            </Col>
            <Col span={8} className="numberBtn">
                <Button name="0" onClick={() => onClick(0)} type="primary" shape="circle">
                    0
                </Button>
            </Col>
            <Col span={8} className="greenBtn">
                <Button disabled={loading} onClick={onSubmit} type="primary" shape="circle">
                    <img src={checkIcon} width='22' height='22' style={{ marginTop: '5px' }} />
                </Button>
            </Col>

        </Row>
    );
};

export default KeyPadComponent;
