import { Button, Col, DatePicker, Form, Modal, Row, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { disabledDate } from "../../../global/helpers";

const TimeModal = (props) => {
	const { initialValues, title, isModalOpen, setIsModalOpen, layout, handleFinish, loading } =
		props;
	const [form] = Form.useForm();
	const [required, setRequired] = useState(false);
	const handleRequired = (value) => {
		if (form.getFieldValue("end_date") || form.getFieldValue("actual_time_out")) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};
	useEffect(() => {
		if (initialValues?.end_date || initialValues?.actual_time_out) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	}, [initialValues]);

	return (
		<Modal
			centered
			title={title}
			open={isModalOpen}
			onCancel={() => {
				setIsModalOpen(false);
			}}
			footer={[
				<Button
					key="1"
					loading={loading}
					type="primary"
					onClick={form.submit}
					htmlType="submit">
					Yes
				</Button>,
				<Button
					key="2"
					onClick={() => {
						setIsModalOpen(false);
					}}>
					No
				</Button>,
			]}>
			<Form
				initialValues={initialValues}
				layout={layout ? layout : "vertical"}
				form={form}
				onFinish={(values) => handleFinish(values)}>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: "Please select start date",
								},
							]}
							name={"date"}
							label="Start date">
							<DatePicker
								className="full_width"
								disabledDate={disabledDate}
								//  defaultValue={singleTimesheet?.date && dayjs(`${singleTimesheet.date}`, 'YYYY-MM-DD')}
								format={"YYYY-MM-DD"}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: required,
									message: "Please select end date",
								},
							]}
							name={"end_date"}
							label="End date">
							<DatePicker
								onChange={handleRequired}
								className="full_width"
								// disabledDate={disabledDate}
								//  defaultValue={singleTimesheet?.date && dayjs(`${singleTimesheet.date}`, 'YYYY-MM-DD')}
								format={"YYYY-MM-DD"}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: "Please select actual time in",
								},
							]}
							name={"actual_time_in"}
							label="Actual time in">
							<TimePicker
								className="full_width"
								//  defaultValue={singleTimesheet?.actual_time_in && dayjs(`${singleTimesheet.actual_time_in} ${singleTimesheet.in_meridiem}`, 'hh:mm A')}
								format={"hh:mm A"}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: required,
									message: "Please select actual time out",
								},
							]}
							name={"actual_time_out"}
							label="Actual time out">
							<TimePicker
								onChange={handleRequired}
								className="full_width"
								//  defaultValue={singleTimesheet?.actual_time_out && dayjs(`${singleTimesheet.actual_time_out} ${singleTimesheet.out_meridiem}`, 'hh:mm A')}
								format={"hh:mm A"}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default TimeModal;
