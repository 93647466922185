import {
	DeleteOutlined,
	DownloadOutlined,
	EditFilled,
	FileExcelOutlined,
	FilePdfOutlined,
	FilterOutlined,
	LoadingOutlined,
	MailOutlined,
	PrinterOutlined,
} from "@ant-design/icons";
import { Checkbox, Col, DatePicker, Row, Switch, Table, Tooltip, message } from "antd";
import Typography from "antd/es/typography/Typography";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerModal from "../../components/custommodal/CustomerModal";
import MainLayout from "../../components/main_layout/MainLayout";
import TimeModal from "../../components/popup/timesheetPopup";
import {
	useAddEditTimesheetMutation,
	useEmployeeTimesheetDownloadMutation,
	useEmployeeTimesheetMailMutation,
	useSingleEmployeeTimesheetQuery,
	useUpdateTimeSheetMutation,
} from "../../features/api/adminApi/timesheet_api";
import errorMessage from "../../global/errorMessage/ErrorMessage";
import { HandleLunchBreak, HandleTotalHours, makeQueryString } from "../../global/helpers";
import "./timesheet.css";
import IndicateLoader from "../../components/indicateloader/IndicateLoader";
import { useSelector } from "react-redux";
import { useViewEmployeeQuery } from "../../features/api/adminApi/employee_api";
import Loader from "../../components/loader/Loader";
const { Title } = Typography;


const EmpTimeSheet = () => {

	const dates = useSelector( ( state ) => state?.auth?.user );
	const start_pay_date = dates?.start_pay_date;
	const end_pay_date = dates?.end_pay_date;
	//Hooks
	// let searchParams = new URL(document.location).searchParams;
	// const from_date = searchParams.get("from_date");
	// const to_date = searchParams.get("to_date");
	const { employeeId } = useParams();
	const {
		data: viewEmployee,
		isLoading: employeeLoading,
	} = useViewEmployeeQuery ( employeeId );

	const [timesheetData, setTimesheetData] = useState([]);
	const [popupType, setPopupType] = useState("");
	const [name, setName] = useState("");

	const [filterOptions, setFilterOption] = useState(false);
	const [columnData, setColumnData] = useState([]);
	const [total, setTotal] = useState(0);
	const [ params, setParams ] = useState({
		from_date: dayjs().format("YYYY-MM-DD"),
		to_date: dayjs().format("YYYY-MM-DD"),
		per_page: 10,
		page: 1,
	});

	const [ toggleDate, setToggleDate ] = useState({ 
		start_date: dayjs().format("YYYY-MM-DD"),
		end_date: dayjs().format("YYYY-MM-DD")
	})

	const [singleTimesheet, setSingleTimesheet] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [timeSheetModal, setTimeSheetModal] = useState(false);
	const [ pdfDownloadLoader, setPdfDownloadLoader ] = useState( false );
	const [ excelDownloadLoader, setExcelDownloadLoader ] = useState( false );	
	const [ printLoader, setPrintLoader ] = useState(false)

	//API Employee entry timesheet

	const {
		data: timeSheetApi,
		isLoading: tableLoading,
		isFetching: tableFetching,
		isSuccess,
		refetch,
	} = useSingleEmployeeTimesheetQuery({ params: makeQueryString(params), id: employeeId });

	const [download] = useEmployeeTimesheetDownloadMutation();
	const [mail, { isLoading: mailLoading }] = useEmployeeTimesheetMailMutation();

	const [editTime, { isLoading: editLoading }] = useUpdateTimeSheetMutation();
	const [addEditTime, { isLoading: addEditLoading }] = useAddEditTimesheetMutation();

	const handleDownload = async ( fileType ) => {

		( fileType === "pdf" ) ? setPdfDownloadLoader( true ) : setExcelDownloadLoader( true );

		if (timesheetData.length <= 0) {
			message.info("No data to download");
			( fileType === "pdf" ) ? setPdfDownloadLoader( false ) : setExcelDownloadLoader( false );

		} else {

			let downloadFileName = ""; 
			const queryParams = {
				...params,
				id: employeeId ?? "",
				file_type: fileType ?? ""
			};
			download( queryParams )
				.unwrap()
				.then((res) => {
					const url = URL.createObjectURL(res);
					const a = document.createElement("a");
					a.href = url;
					( fileType === "pdf" ) ? downloadFileName = "Timesheet.pdf" : downloadFileName = "Timesheet.xlsx";
					a.download = downloadFileName; 
					a.click();
					( fileType === "pdf" ) ? setPdfDownloadLoader( false ) : setExcelDownloadLoader( false );
				})
				.catch((err) => {
					message.error(err.data ?? "Something Went wrong");
					( fileType === "pdf" ) ? setPdfDownloadLoader( false ) : setExcelDownloadLoader( false );
				});
		}
	};

	const handleAddEditTime = async (values) => {
		try {
			const formData = {
				employee_id: employeeId,
				date: values.date ? dayjs(values.date).format("YYYY-MM-DD") : null,
				end_date: values.end_date ? dayjs(values.end_date).format("YYYY-MM-DD") : null,
				actual_time_in: values.actual_time_in
					? dayjs(values.actual_time_in, "hh:mm").format("hh:mm")
					: null,
				record_time_in: values.record_time_in
					? dayjs(values.record_time_in, "hh:mm").format("hh:mm")
					: null,
				actual_time_out: values.actual_time_out
					? dayjs(values.actual_time_out, "hh:mm").format("hh:mm")
					: null,
				record_time_out: values.record_time_out
					? dayjs(values.record_time_out, "hh:mm").format("hh:mm")
					: null,
				in_meridiem: values.actual_time_in
					? dayjs(values.actual_time_in, "A").format("A")
					: values.record_time_in
					? dayjs(values.actual_time_in, "A").format("A")
					: null,
				out_meridiem: values.actual_time_out
					? dayjs(values.actual_time_out, "A").format("A")
					: values.actual_time_out
					? dayjs(values.actual_time_out, "A").format("A")
					: null,
			};
			// const result = await editTime({
			//     id: singleTimesheet.id,
			//     formData: formData
			// }).unwrap()
			const result = await addEditTime({
				id: singleTimesheet?.id ? singleTimesheet?.id : employeeId,
				type: popupType,
				formData: formData,
			}).unwrap();
			message.success(result.message);
			setTimeSheetModal(false);
			setIsModalOpen(false);
		} catch (err) {
			errorMessage(err?.data?.error);
		}
	};

	const handleMail = async () => {
		if (timesheetData.length <= 0) {
			message.info("No data to mail");
			setIsModalOpen(false);
		} else {
			try {
				const setting = {
					...params,
					id: employeeId ?? "",
				};
				const response = await mail(setting).unwrap();
				message.success(response.message);
				setIsModalOpen(false);
			} catch (err) {
				errorMessage(err?.data?.error);
			}
		}
	};
	const handleDateRange = (value = []) => {
		if (value?.length > 1) {
			if (value[0] && value[1]) {
				const setting = {
					...params,
					from_date: dayjs(value[0]).format("YYYY-MM-DD"),
					to_date: dayjs(value[1]).format("YYYY-MM-DD"),
				};
				setParams(setting);
			}
		} else {
			const setting = {
				...params,
				from_date: "",
				to_date: "",
			};
			setParams(setting);
		}
	};

	const options = [
		{
			label: "Date",
			value: "Date",
		},
		{
			label: "Checkin ID",
			value: "Checkin ID",
		},
		{
			label: "Actual In",
			value: "Actual In",
		},
		{
			label: "Actual Out",
			value: "Actual Out",
		},
		{
			label: "Record In",
			value: "Record In",
		},
		{
			label: "Record Out",
			value: "Record Out",
		},
		{
			label: "Total Hours",
			value: "Total Hours",
		},
		// {
		//     label: "Lunch Break",
		//     value: "Lunch Break",
		// },
	];

	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			render: (_, record) => dayjs(record?.date).format("YYYY-MM-DD"),
		},
		{
			title: "Checkin ID",
			dataIndex: "check_in_id",
		},
		{
			title: "Day",
			dataIndex: "day",
			render: (_, record) => {
				return record?.date ? dayjs(record?.date).format("dddd") : "";
			},
		},
		{
			title: "Actual In",
			dataIndex: "actual_time_in",
			render: (_, record) => {
				return `${record?.actual_time_in ? record?.actual_time_in : ""} ${
					record?.in_meridiem ? record?.in_meridiem : ""
				}`;
			},
		},
		{
			title: "Actual Out",
			dataIndex: "actual_time_out",
			render: (_, record) => {
				return `${record?.actual_time_out ? record?.actual_time_out : ""} ${
					record?.out_meridiem ? record?.out_meridiem : ""
				}`;
			},
		},
		{
			title: "Record In",
			// dataIndex: "record_time_in",
			render: (_, record) => {
				// return `${record?.record_time_in ? record?.record_time_in : ""} ${record?.in_meridiem ? record?.in_meridiem : ""
				//   }`;
				return `${record?.record_time_in ? record?.record_time_in : ""}`;
			},
		},
		{
			title: "Record Out",
			dataIndex: "record_time_out",
			render: (_, record) => {
				// return `${record?.record_time_out ? record?.record_time_out : ""} ${record?.out_meridiem ? record?.out_meridiem : ""
				//   }`;
				return `${record?.record_time_out ? record?.record_time_out : ""}`;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
		},
		{
			title: "Lunch Break",
			dataIndex: "lunch_break",
			render: (_, record) => {
				return HandleLunchBreak(record);
			},
		},
		{
			title: "Total Hours",
			dataIndex: "total_hours",
			render: (_, record) => {
				return (
					// <div className={record.status === "Check IN" ? "demo" : ""}>
					HandleTotalHours(record)
					// </div>
				);
			},
		},
		{
			title: "Action",
			align: "center",
			render: (_, record) => {
				return (
					<>
						<Row gutter={15} justify={"center"}>
							<Col>
								<Tooltip title="Edit" className={""}>
									<EditFilled
										onClick={() => {
											setPopupType("edit");
											const initialValues = {
												id: record.id ? record.id : null,
												date: record?.date
													? dayjs(record.date, `YYYY-MM-DD`)
													: null,
												end_date: record?.end_date
													? dayjs(record.end_date, `YYYY-MM-DD`)
													: null,
												actual_time_in: record.actual_time_in
													? dayjs(
															`${record.actual_time_in} ${record.in_meridiem}`,
															`hh:mm A`
													  )
													: null,
												record_time_in: record.record_time_in
													? dayjs(
															`${record.record_time_in} ${record.in_meridiem}`,
															`hh:mm A`
													  )
													: null,
												actual_time_out: record?.actual_time_out
													? dayjs(
															`${record.actual_time_out} ${record.out_meridiem}`,
															`hh:mm A`
													  )
													: null,
												record_time_out:
													record.record_time_out &&
													dayjs(
														`${record.record_time_out} ${record.out_meridiem}`,
														`hh:mm A`
													),
											};
											setSingleTimesheet(initialValues);
											setTimeSheetModal(true);
										}}
										style={{
											cursor: "pointer",
										}}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip
									title="Delete"
									style={{ paddingTop: "10px" }}
									className={""}>
									<DeleteOutlined
										onClick={() => {
											setPopupType("delete");
											setSingleTimesheet(record);
											setIsModalOpen(true);
										}}
										style={{
											cursor: "pointer",
										}}
									/>
								</Tooltip>
							</Col>
						</Row>
					</>
				);
			},
		},
	];

	const breadcrumbs = [
		{
			title: "Dashboard",
			href: "/admin/dashboard",
		},
		{
			title: "Timesheet",
			href: "/admin/timesheets",
		},
		{
			title: name,
		},
	];

	const handleToggleDate = ( value ) => {

		let from_date = "";
		let to_date = "";
		let diffValue = Math.abs( value );
		const allowDiffValues = [ 1, 6, 13, 29 ];

		if( diffValue === 0 ) diffValue++ ;

		if ( allowDiffValues.includes( diffValue ) ) {
			
			if( diffValue === 1 ){

				from_date = dayjs( toggleDate?.start_date ).subtract( diffValue, 'day' ).format("YYYY-MM-DD");			
				to_date = dayjs( toggleDate?.end_date ).subtract( diffValue, 'day' ).format("YYYY-MM-DD");		
			}else{
				
				let start_date = dayjs( toggleDate?.start_date ).subtract( 1, 'day' ).format("YYYY-MM-DD");
				let end_date = dayjs( toggleDate?.end_date ).subtract( 1, 'day').format("YYYY-MM-DD");

				from_date = dayjs( start_date ).subtract( diffValue, 'day' ).format("YYYY-MM-DD");			
				to_date = dayjs( end_date ).subtract( diffValue, 'day' ).format("YYYY-MM-DD");		
			}
		}

		if( from_date && to_date ){	
			setParams({
				...params,
				from_date, 
				to_date
			});
		}
	};

	const filterChange = (checkedValues) => {
		//Add action into array
		let checkNewValues = [...checkedValues, "Action"];

		//Convert array value to array of object
		const filterData = checkNewValues.map((val) => ({ title: val }));

		//Compare check values and columns value
		let filterArr = columns.filter((col1) => {
			return filterData.some((col2) => col1.title === col2.title);
		});
		setColumnData(filterArr);
	};

	//useEffect
	useEffect(() => {
		if (timeSheetApi !== undefined) {
			const { data } = timeSheetApi;
			setTimesheetData(data?.data);
			setName(data?.data[0]?.employee_name);
			setTotal(data?.total);
		}
	}, [timeSheetApi]);

	useEffect(() => {
		if (columns) {
			setColumnData(columns);
		}
	}, []);

	useEffect(() => {
		
		if( viewEmployee ){

			if( viewEmployee?.data?.pay_from_date && viewEmployee?.data?.pay_to_date ){
				setParams({
					...params,
					from_date: dayjs( viewEmployee?.data?.pay_from_date ).format("YYYY-MM-DD"),
					to_date: dayjs( viewEmployee?.data?.pay_to_date ).format("YYYY-MM-DD")
				})
				setToggleDate({
					...toggleDate,
					start_date: dayjs( viewEmployee?.data?.pay_from_date ).format("YYYY-MM-DD"),
					end_date: dayjs( viewEmployee?.data?.pay_to_date ).format("YYYY-MM-DD")
				})
			}else{
				setParams({
					...params,
					from_date: dayjs( start_pay_date ).format("YYYY-MM-DD"),
					to_date: dayjs( end_pay_date ).format("YYYY-MM-DD")
				})
				setToggleDate({
					...toggleDate,
					start_date: dayjs( start_pay_date ).format("YYYY-MM-DD"),
					end_date: dayjs( end_pay_date ).format("YYYY-MM-DD")
				})				
			}			  
		}
	}, [ viewEmployee ])

	useEffect(() => {
		refetch();
	}, [params]);

	useEffect(() => {
		const time = setInterval(() => {
			refetch();
		}, 60000);
		return () => clearInterval(time);
	}, []);

	return (
		<>
			{/* Layout */}
			{
				employeeLoading === true ? (
					<Loader loading={true} />
				) : (
						<MainLayout
							title={"Timesheet"}
							breadcrumbs={breadcrumbs}
							upload={[]}
							button={[
								{
									label: "New Check In",
									func: () => {
										setPopupType("add");
										setSingleTimesheet(null);
										setTimeSheetModal(true);
									},
								},
							]}
							banner={false}
						>
							<div>
								<div style={{ paddingBottom: "33px" }}>
									<Typography>
										<Title style={{ fontSize: "22px", fontWeight: "bold" }}>
											Employee Name: {`${name ?? ""}`}
										</Title>
									</Typography>
								</div>
								<div className="report_icons">
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											gap: "15px",
											padding: "8px",
											justifyContent: "center",
											background: "#ededed",
											borderRadius: "15px 15px 0 0",
											// width: 400,
										}}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: "10px",
											}}>
											{/* <Input
												placeholder="Search Employees"
												// onSearch={() => { }}
												style={{
													width: 250,
													height: '40px'
												}}
											/> */}
											<div
												style={{
													background: "white",
													padding: "10px",
													borderRadius: "10px",
												}}>
												
												<DatePicker.RangePicker
													value={[
														params.from_date ? dayjs(params.from_date, "YYYY-MM-DD") : null,
														params.to_date ? dayjs(params.to_date, "YYYY-MM-DD") : null,
													]}
													onChange={(values) => handleDateRange(values)}
												/>
											</div>
											<div>
												<Switch
													defaultChecked={false}
													onChange={( switchOn )=>{
														if( switchOn ){
															const diffValue = dayjs( toggleDate?.start_date ).diff( dayjs( toggleDate?.end_date ), 'day' )
															handleToggleDate( diffValue )
														}else{
															setParams({
																...params,
																from_date: dayjs( toggleDate?.start_date ).format("YYYY-MM-DD"),
																to_date: dayjs( toggleDate?.end_date ).format("YYYY-MM-DD")
															});
														}
														
													}}
												/>
											</div>
											<FilterOutlined
												onClick={() => {
													setFilterOption(!filterOptions);
												}}
												style={{ fontSize: "26px", cursor: "pointer" }}
											/>
										</div>
									</div>
									<div style={{ display: "flex", gap: "24px" }}>
										{/* <div
											onClick={() => handleDownload()}
											style={{
												display: "flex",
												alignItems: "center",
												gap: "5px",
											}}
											className="tableTopLinks">
											{
												downloadLoader ?					
												<IndicateLoader />  :
												<DownloadOutlined />
											}
											<span>Download</span>
										</div> */}
										{
											pdfDownloadLoader ? 
												<LoadingOutlined />
											:
												<FilePdfOutlined
													style={{
														fontSize: '24px'
													}} 
													onClick={ () => handleDownload( "pdf" ) }
												/>
										}

										{				
											excelDownloadLoader ? 
												<LoadingOutlined />
											:
												<FileExcelOutlined 
													name="Excel Download"
													style={{
														fontSize: '24px'
													}}
													onClick={() => {
														handleDownload( "excel" )
													}}
												/>
										}	
										<div
											onClick={() => {
												setPopupType("email");
												setIsModalOpen(true);
											}}
											style={{
												display: "flex",
												alignItems: "center",
												gap: "5px",
											}}
											className="tableTopLinks">
											<MailOutlined />
											<span>Email</span>
										</div>
										<div
											onClick={async () => {
												setPrintLoader(true)
												if (timesheetData.length <= 0) {
													message.info("No data to print");
													setPrintLoader(false)
												}else {
													try {
														const detail = {
															...params,
															id: employeeId ?? ""
														}

														const result = await download(detail).unwrap();
														setPrintLoader(false)
														// Create a new URL object to get the file URL from the blob
														const fileUrl = window.URL.createObjectURL(result);
														// Create a new window to display the file and trigger the print dialog
														const printWindow = window.open(fileUrl);
														printWindow.onload = () => {
															printWindow.print();
														};
													} catch (err) {
														setPrintLoader(false)
														message.error(err.data ?? "Something Went wrong");
													}
												}
											}}
											style={{
												display: "flex",
												alignItems: "center",
												gap: "5px",
											}}
											className="tableTopLinks">
											{
												printLoader ? 
												<IndicateLoader /> : 
												<PrinterOutlined />
											}
											<span>Print</span>
										</div>
									</div>
								</div>
							{filterOptions && (
								<div
									className="table_check_box"
									style={{
										// width: "100%",
										display: "flex",
										background: "#ededed",
										padding: "10px",
										gap: "25px",
									}}>
									<Checkbox.Group
										options={options}
										onChange={filterChange}
										defaultValue={[
											"Date",
											"Checkin ID",
											"Actual In",
											"Actual Out",
											"Record In",
											"Record Out",
											"Status",
											"Lunch Break",
											"Total Hours",
										]}
									/>
								</div>
							)}
							<div className="outlet_table timesheetTable">
								<Table
									size="small"
									columns={columnData}
									// loading={tableFetching}
									dataSource={timesheetData}
									pagination={{
										total: total ?? 0,
										defaultPageSize: 10,
										pageSizeOptions: [10, 25, 50, 100, 500],
										showSizeChanger: true,
										onChange: (page, per_page) => {
											const setting = {
												...params,
												page,
												per_page,
											};
											setParams(setting);
										},
									}}
									scroll={{
										x: 700,
										y: 400,
									}}
								/>
							</div>
						</div>
						{isModalOpen ? (
							<CustomerModal
								handleFinish={popupType === "email" ? handleMail : handleAddEditTime}
								title={popupType === "email" ? "Mail" : "Delete Employee Entry"}
								layout="vertical"
								initialValues={singleTimesheet}
								setIsModalOpen={setIsModalOpen}
								isModalOpen={isModalOpen}
								loading={
									editLoading === true || addEditLoading === true || mailLoading === true
										? true
										: false
								}>
								{popupType === "delete"
									? "Do you need to delete Employee entry ?"
									: popupType === "email"
									? "Do you need to send Email ?"
									: null}
							</CustomerModal>
						) : null}
						{timeSheetModal ? (
							<TimeModal
								handleFinish={handleAddEditTime}
								title={popupType === "add" ? "Add Entry Time" : "Edit Entry Time"}
								layout="vertical"
								initialValues={singleTimesheet}
								setIsModalOpen={setTimeSheetModal}
								isModalOpen={timeSheetModal}
								loading={editLoading === true || addEditLoading === true ? true : false}
							/>
						) : null}
					</MainLayout>
			)
		}
		</>
	);
};

export default EmpTimeSheet;
