import React, { useState } from "react"
import { Breadcrumb, Col, Layout, message, Row, theme, Typography } from "antd"
import { Button, Checkbox, Form, Input } from "antd"
import "../login.css"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAdminLoginApiMutation, useResetPasswordMutation } from "../../../features/api/adminApi/admin_auth.js"
import { setUser } from "../../../features/slice/authSlice.js"
import errorMessage from "../../errorMessage/ErrorMessage"
import circleInsideLogo from "../../../assets/circle-inside-logo.png"
import "./adminlogin.css"


const { Content } = Layout

const ForgetPasswordEmail = () => {

    //Hooks
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [passwordShow, setPasswordShow] = useState(false)
    let params = new URL(document.location).searchParams;
    let email = params.get("email");
    let token = params.get("token");

    //Admin Login Api
    const [adminLoginApi, { isLoading }] = useResetPasswordMutation()

    const handleLogin = (values) => {
        values.email = email
        values.token = token
        adminLoginApi(values)
            .unwrap()
            .then((res) => {
                message.success(res.message)
                navigate('/admin/login')
            }).catch(err => errorMessage(err?.data?.error))
    }

    return (
        <Layout className="layout">
            <Row justify={"center"}>
                {/* <Col sapn={24}>
                        <div className="logo">
                            <img src={circleInsideLogo} alt="" />
                        </div>
                    </Col> */}
                <Col span={24} className="title">
                    <Typography.Title style={{
                        textAlign: "center"
                    }}>
                        Forget Password
                    </Typography.Title>
                </Col>
                <Form
                    form={form}
                    layout={"vertical"}

                    onFinish={handleLogin}>
                    <Row justify={"center"}>
                        <Col span={24}>
                            <Form.Item rules={[{
                                min: 8,
                            }]} name={'password'} label="New Password">
                                <Input.Password placeholder='New Password'></Input.Password>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item rules={[
                                {
                                    min: 8,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "The Confirm passwords that you entered does not match!"
                                            )
                                        )
                                    },
                                }),
                            ]} name={'password_confirmation'} label="Confirm Password">
                                <Input.Password placeholder='Confirm Password'></Input.Password>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button
                                loading={isLoading}
                                style={{
                                    width: "120px",
                                }}
                                type="primary"
                                htmlType="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
            {/* </div> */}
        </Layout >
    )
}

export default ForgetPasswordEmail
