import { mainApi } from "../mainApi";

export const adminSettingApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //sign in Create
    signInOutInformationCreate: builder.mutation({
      query: (formData) => ({
        url: `/company/singn-setting/save`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Signin-Out"],
    }),
    signInOutInformationDelete: builder.mutation({
      query: (id) => {
        return {
          url: `/company/singn-setting/${id}`,
          method: "Delete",
        };
      },
      invalidatesTags: ["Signin-OutDelete"],
    }),

    // //Get profile
    // adminGetMe: builder.query({
    //   query: () => `/company/me`,
    //   providesTags: ["Profile"],
    // }),
    // //logo Update
    // adminLogoUpdate: builder.mutation({
    //   query: ({ url, formData }) => ({
    //     url: `/company/logo/${url}`,
    //     method: "POST",
    //     body: formData,
    //   }),
    //   invalidatesTags: ["Profile"],
    // }),
  }),
});

export const {
  useSignInOutInformationCreateMutation,
  useSignInOutInformationDeleteMutation,
  useAdminGetMeQuery,
  useAdminLogoUpdateMutation,
} = adminSettingApi;
