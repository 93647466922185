import {
	Col,
	Divider,
	Form,
	Row,
	Spin,
	Typography,
	Input,
	Select,
	DatePicker,
	Button,
	TimePicker,
	message,
	InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import {
	useEditSettingMutation,
	useListSettingQuery,
} from "../../features/api/superadminApi/customer_api";
import dayjs from "dayjs";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import errorMessage from "../../global/errorMessage/ErrorMessage";
import { NumericFormat, PatternFormat } from "react-number-format";
import {
	_handleCheckIn,
	autoFormatPhoneNumber,
	formatNumber,
	formatTime,
	handleOverTime,
	parseNumber,
	parseTime,
} from "../../global/helpers";

const AdvanceSettings = () => {
	const [form] = Form.useForm();
	const [setting, setSetting] = useState(null);
	const [editSetting, { editLoading }] = useEditSettingMutation();
	const {
		data: settingData,
		isSuccess: success,
		isLoading,
		isFetching,
		refetch,
	} = useListSettingQuery();

	const handleDate = (type) => {
		const month = dayjs().get("month");
		const year = dayjs().get("year");
		const days = dayjs().daysInMonth();
		if (type === "start") {
			return dayjs(`${year}-${month}-01`);
		} else {
			return dayjs(`${year}-${month}-${days}`);
		}
	};

	const format = "hh:mm A";
	const graceFormat = "hh:mm";

	const [startPayDate, setStartPayDate] = useState(null);

	const handleAccountSubmit = (values) => {
		
		values.company_id = settingData?.data.company_id;
		values.system_check_out_time = dayjs(values.system_check_out_time).format(format);
		// values.break_time = dayjs(values.break_time).format("mm");
		values.check_in_time = dayjs(values.check_in_time).format(format);
		values.check_out_time = dayjs(values.check_out_time).format(format);
		values.check_in_grace_time = dayjs(values.check_in_grace_time).format("HH:mm");
		values.check_out_grace_time = dayjs(values.check_out_grace_time).format("HH:mm");
		values.start_pay_date = dayjs(values.start_pay_date).format("YYYY-MM-DD");
		values.end_pay_date = dayjs(values.end_pay_date).format("YYYY-MM-DD");
		// values.overtime_per_day = values.overtime_per_day.replace("/Day", "");
		// values.overtime_per_week = values.overtime_per_week.replace("/Weekly", "");
		// values.overtime_per_bi_week = values.overtime_per_bi_week.replace("/Bi-Weekly", "");
		// values.overtime_per_month = values.overtime_per_month.replace("/Month", "");
		const params = {
			formData: values,
			id: settingData?.data.id,
		};
		editSetting(params)
			.unwrap()
			.then((res) => {
				message.success(res.message);
			})
			.catch((err) => {
				errorMessage(err.data.error);
			});
	};

	const handlePaymentType = (values) => {
		if (values === "day") {
			form.setFieldsValue({
				start_pay_date: dayjs(),
				end_pay_date: dayjs(),
			});
		} else if (values === "month") {
			form.setFieldsValue({
				start_pay_date: dayjs(),
				end_pay_date: dayjs().add(29, "day"),
			});
		} else if (values === "week") {
			form.setFieldsValue({
				start_pay_date: dayjs(),
				end_pay_date: dayjs().add(6, "day"),
			});
		} else if (values === "bi-week") {
	
			form.setFieldsValue({
				start_pay_date: dayjs(),
				end_pay_date: dayjs().add(13, "day"),
			});
		}
	};
	const handleDateChange = (value) => {
		const payType = form.getFieldValue("payment_type");

		if (value) {
			if (payType === "day") {
				form.setFieldValue("end_pay_date", dayjs(value));
			} else if (payType === "month") {
				form.setFieldValue("end_pay_date", dayjs(value).add(29, "day"));
			} else if (payType === "week") {
				form.setFieldValue("end_pay_date", dayjs(value).add(6, "day"));
			} else if (payType === "bi-week") {
				form.setFieldValue("end_pay_date", dayjs(value).add(13, "day"));
			}
		} else {
			form.setFieldValue("end_pay_date", null);
		}
	};
	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});

	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener("resize", setDimension);

		return () => {
			window.removeEventListener("resize", setDimension);
		};
	}, [screenSize]);

	useEffect(() => {
		if (settingData) {
			setSetting(settingData.data);
			const {
				check_in_grace_time,
				check_out_grace_time,
				check_in_time,
				check_out_time,
				start_pay_date,
				end_pay_date,
				system_check_out_time,
				// break_time,
				...others
			} = settingData.data;
			// const breaks = break_time ? `00:${break_time}` : "00:00";

			form.setFieldsValue({
				check_in_grace_time: check_in_grace_time && dayjs(check_in_grace_time, "HH:mm"),
				check_out_grace_time: check_out_grace_time && dayjs(check_out_grace_time, "HH:mm"),
				system_check_out_time:
					system_check_out_time && dayjs(system_check_out_time, "HH:mm A"),
				// break_time: break_time ? dayjs(breaks, "HH:mm") : null,
				check_in_time: check_out_grace_time && dayjs(check_in_time, "hh:mm A"),
				check_out_time: check_out_time && dayjs(check_out_time, "hh:mm A"),
				start_pay_date: start_pay_date && dayjs(start_pay_date),
				end_pay_date: end_pay_date && dayjs(end_pay_date),
				...others,
			});
		}
	}, [settingData]);

	return (
		<Spin spinning={isLoading === true || isFetching === true ? true : false}>
			<Form
				labelAlign="left"
				labelWrap
				form={form}
				onFinish={handleAccountSubmit}
				layout={screenSize.dynamicWidth >= 1000 ? "horizontal" : "vertical"}>
				<Row>
					<Col span={24}>
						<Typography.Title level={4}>Account Settings</Typography.Title>
						<Divider style={{ margin: 0, marginBottom: "20px" }} />
					</Col>
					<Col span={24}>
						<Col span={24}>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please enter check-in id",
									},
								]}
								name={"checkin_ref"}
								label="Check-In Id"
								className="labelCustomSize">
								<Input
									onKeyPress={_handleCheckIn}
									placeholder="Check-In Id"
									className="account_label"
								/>
								{/* <InputNumber
									onKeyPress={(evt) => {
										if (
											(evt.which != 8 && evt.which != 0 && evt.which < 48) ||
											evt.which > 57
										) {
											evt.preventDefault();
										}
									}}
									placeholder="Check in id"
									className="account_label"
								/> */}
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please enter employee id",
									},
								]}
								name={"employee_ref"}
								label="Employee Id"
								className="labelCustomSize">
								<Input
									onKeyPress={(event) => {
										var regex = new RegExp("^[a-zA-Z0-9]+$");
										var key = String.fromCharCode(
											!event.charCode ? event.which : event.charCode
										);
										if (!regex.test(key)) {
											event.preventDefault();
											return false;
										}
									}}
									placeholder="Employee Id"
									className="account_label"
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<div style={{ display: "flex", gap: "20px" }} className="flexRemove">
								<Form.Item
									rules={[
										{
											required: true,
											message: "Please select check in time",
										},
									]}
									name={"check_in_time"}
									label="Regular check-in time"
									className="labelCustomSize">
									<TimePicker
										use12Hours
										format={"hh:mm A"}
										// defaultValue={dayjs("09:00 AM", format)}
									/>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Please select check in grace time",
										},
									]}
									name={"check_in_grace_time"}
									label="Grace Time">
									<TimePicker
										onChange={(values) => {
											form.setFieldValue({
												check_in_grace_time: dayjs(values).format("HH:mm"),
											});
										}}
										format={"HH:mm"}
									/>
								</Form.Item>
							</div>
						</Col>
						<Col span={24}>
							<div style={{ display: "flex", gap: "20px" }} className="flexRemove">
								<Form.Item
									rules={[
										{
											required: true,
											message: "Please select check out",
										},
									]}
									name={"check_out_time"}
									label="Regular check-out time"
									className="labelCustomSize">
									<TimePicker
										format={"hh:mm A"}
										use12Hours
										// defaultValue={dayjs("06:00 PM", format)}
									/>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Please select check out grace time",
										},
									]}
									name={"check_out_grace_time"}
									label="Grace Time">
									<DatePicker.TimePicker
										onChange={(values) => {
											form.setFieldValue({
												check_out_grace_time: dayjs(values).format("HH:mm"),
											});
										}}
										// use12Hours
										format={"HH:mm"}
										// defaultValue={dayjs("00:05", graceFormat)}
									/>
								</Form.Item>
							</div>
						</Col>
						<Col span={24}>
							<div style={{ display: "flex", gap: "20px" }} className="flexRemove">
								<Form.Item
									rules={[
										{
											required: true,
											message: "Please select system checkout time",
										},
									]}
									name={"system_check_out_time"}
									label="System Check-Out"
									className="labelCustomSize">
									<TimePicker
										use12Hours
										format={"hh:mm A"}
										// defaultValue={dayjs("09:00 AM", format)}
									/>
								</Form.Item>
							</div>
						</Col>
						<Col span={24}>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please select break time",
									},
								]}
								name={"break_time"}
								label="Break time"
								className="labelCustomSize">
								<InputNumber
									className="setting_with_prefix"
									addonAfter="min"
									maxLength={2}
									max={60}
									min={0}
								/>
								{/* <TimePicker
									// use12Hours
									format={"mm"}
									// defaultValue={dayjs("09:00 AM", format)}
								/> */}
							</Form.Item>
						</Col>
						{/* <Col
							span={24}
							style={{ display: "flex", gap: "20px" }}
							className="flexRemove">
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please enter overtime per day",
									},
									{
										pattern: new RegExp("^([0-9]?[0-9]?[0-9]):[0-9][0-9]$"),
										message: "Please enter valid Time",
									},
								]}
								name={"overtime_per_day"}
								label="Overtime"
								className="labelCustomSize custom-span">
								<PatternFormat
									onBlur={(e) => handleOverTime(e, 24, form, "overtime_per_day")}
									placeholder="000:00"
									format="###:##"
									className="max_width patternformatDesign"
								/>
							</Form.Item>
							<Form.Item
								className="labelCustomSize custom-span-1"
								rules={[
									
									{
										pattern: new RegExp("^([0-9]?[0-9]?[0-9]):[0-9][0-9]$"),
										message: "Please enter valid Time",
									},
								]}
								name={"overtime_per_week"}>
							
								<PatternFormat
									onBlur={(e) =>
										handleOverTime(e, 168, form, "overtime_per_week")
									}
									placeholder="000:00"
									format="###:##"
									className="max_width patternformatDesign"
								/>
							</Form.Item>
							<Form.Item
								rules={[
									
									{
										pattern: new RegExp("^([0-9]?[0-9]?[0-9]):[0-9][0-9]$"),
										message: "Please enter valid Time",
									},
								]}
								className="labelCustomSize custom-span-2"
								name={"overtime_per_bi_week"}>
								<PatternFormat
									onBlur={(e) =>
										handleOverTime(e, 336, form, "overtime_per_bi_week")
									}
									placeholder="000:00"
									format="###:##"
									className="max_width patternformatDesign full_width"
								/>
							</Form.Item>
							<Form.Item
								className="labelCustomSize custom-span-3"
								rules={[
									
									{
										pattern: new RegExp("^([0-9]?[0-9][0-9]):[0-9][0-9]$"),
										message: "Please enter valid Time",
									},
								]}
								name={"overtime_per_month"}>
								<PatternFormat
									onBlur={(e) =>
										handleOverTime(e, 744, form, "overtime_per_month")
									}
									placeholder="00:00"
									format="###:##"
									className="max_width patternformatDesign full_width"
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please enter overtime pay",
									},
								]}
								name={"overtime_pay"}
								label="Overtime Pay"
								className="labelCustomSize">
								<InputNumber
									className="setting_with_prefix"
									addonAfter="%"
									maxLength={3}
									max={100}
								/>
							</Form.Item>
						</Col> */}
						<Col span={24}>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please enter payment type",
									},
								]}
								name={"payment_type"}
								label="Payment Type"
								className="labelCustomSize">
								<Select
									style={{ width: "150px" }}
									defaultValue="Day"
									onChange={handlePaymentType}
									options={[
										{
											value: "day",
											label: "Day",
										},
										{
											value: "week",
											label: "Week",
										},
										{
											value: "bi-week",
											label: "Bi-week",
										},
										{
											value: "month",
											label: "Month",
										},
									]}
								/>
							</Form.Item>
						</Col>
						<Col
							span={24}
							// style={{ display: "flex", gap: "20px" }}
						>
							<Row gutter={5}>
								<Col>
									<Form.Item
										rules={[
											{
												required: true,
												message: "Please enter start pay date",
											},
										]}
										name={"start_pay_date"}
										label="Pay period"
										className="labelCustomSize">
										<DatePicker
											className="full_width"
											defaultValue={dayjs()}
											placeholder="Start Date"
											onChange={handleDateChange}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={10} lg={6} xl={6}>
									<Form.Item
										rules={[
											{
												required: true,
												message: "Please enter end pay date",
											},
										]}
										name={"end_pay_date"}
										className="event-none">
										<DatePicker
											// className="full_width"
											// style={{
											// 	width: "130px",
											// }}
											defaultValue={dayjs()}
											placeholder="End Date"
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={24} className="employee_view_btn">
							{/* <div>
                            <Button type="primary" htmlType="submit">
                                Cancel
                            </Button>
                        </div> */}
							<div>
								<Button
									loading={editLoading}
									type="primary"
									htmlType="submit"
									className="saveButtonPrimary"
									icon={<CheckOutlined />}>
									Save
								</Button>
							</div>
						</Col>
					</Col>
				</Row>
			</Form>
		</Spin>
	);
};

export default AdvanceSettings;
