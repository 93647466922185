import { Divider, Form, Row, Spin, Typography, Col, Input, Button, message, InputNumber } from "antd"
import React, { useEffect, useState } from "react"
import ChangePassword from "../../components/super_admin_sections/ChangePassword"
import { useEditPasswordMutation } from "../../features/api/superadminApi/customer_api"
import errorMessage from "../../global/errorMessage/ErrorMessage"
import { autoFormatPhoneNumber, decrypt } from "../../global/helpers"
import { PatternFormat } from "react-number-format"
import { CheckOutlined } from "@ant-design/icons"
import { useEditEmployeeMutation } from "../../features/api/adminApi/employee_api"
import { useProfileAdminUpdateMutation } from "../../features/api/adminApi/adminSetting"
import { useDispatch } from "react-redux"
import { setUser } from "../../features/slice/authSlice"
import ReactGoogleMapsLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import AddressSuggestion from "../address_suggestion/AddressSuggestion"

const Profile = (props) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { profileData, loading, image, setImage, fileList } = props
    const [visibleFields, setVisibleFeilds] = useState(false)
    const [search, setSearch] = useState("")
    const [value, setValue] = useState("")
    const handleNoResult = () => {
        console.log("No results for ", search)
    }

    const handleStatusUpdate = (status) => {
        console.log(status)
    }
    const handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        setSearch("")
        setValue(geocodedPrediction.formatted_address)
        form.setFieldValue("address", geocodedPrediction.formatted_address)
    }
    const handleInputChange = (e) => {
        setSearch(e.target.value)
        setValue(e.target.value)
        form.setFieldValue("address", e.target.value)
    }

    const [editPassword, { isLoading: editPasswordLoading }] = useEditPasswordMutation()
    const [editProfile, { isLoading: editLoading }] = useProfileAdminUpdateMutation()

    const handleProfileSubmit = async (values) => {
        try {
            // formData.append('profile_image', fileList[0])
            const formData = new FormData()
            for (const item in values) {
                if (values[item]) {
                    formData.append(item, values[item])
                }
            }
            // if (fileList.length >= 1) {
            //     formData.set('logo', fileList[0])
            // }
            const result = await editProfile(formData).unwrap()
            message.success(result.message)
            setVisibleFeilds(false)
            dispatch(
                setUser({
                    user: result.data,
                })
            )
        } catch (err) {
            errorMessage(err.data.error)
        }
    }
    const handleShowForm = () => {
        setVisibleFeilds(!visibleFields)
    }

    const handleChangePassword = (values) => {
        values.customer_id = profileData.id
        const params = {
            formData: values,
            role: "customer",
        }
        editPassword(params)
            .unwrap()
            .then((res) => message.success(`${res.message}`))
            .catch((err) => errorMessage(err.data.error))
    }

    useEffect(() => {
        if (profileData) {
            const { hash_password, password, ...others } = profileData
            setValue(others?.address ? others?.address : null)
            form.setFieldsValue({
                password: decrypt(hash_password),
                ...others,
            })
        }
    }, [profileData])
    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                labelWrap
                wrapperCol={{
                    span: 6
                }}
                labelCol={{
                    span: 4,
                }}
                // initialValues={{
                //     phone_number : profileData?.phone_number
                // }}
                onFinish={handleProfileSubmit}
                labelAlign="left"
                layout={"horizontal"}>
                <Row
                    style={{
                        heignt: "100%",
                    }}
                    gutter={[10, 20]}>
                    <Col span={24}>
                        <Typography.Title level={4}>Profile</Typography.Title>
                        <Divider style={{ margin: 0 }} />
                    </Col>
                    <Col span={24}>
                        <Row gutter={10}>
                            <Col span={24}>
                                <Form.Item name={"name"} label="Company Name">
                                    <Input placeholder="Company Name" className="max_width" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"contact_name"} label="Contact Name">
                                    <Input placeholder="Contact Name" className="max_width" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"email"} label="Email">
                                    <Input type="email" placeholder="Email" className="max_width" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"phone_number"} label="Phone Number">
                                    {/* <PatternFormat
                                        format="+1 ### ### ####"
                                        className="max_width patternformatDesign"
                                    /> */}
                                    <InputNumber
                                        // stringMode
                                        maxLength={15}
                                        onChange={(e) => {
                                            form.setFieldValue('phone_number', autoFormatPhoneNumber(e))
                                        }}
                                        // onBlur={(e) => {
                                        //     form.setFieldValue('phone_number', autoFormatPhoneNumber(e))
                                        // }}
                                        placeholder="Phone Number"
                                        className="full_width"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"address"} label="Address">
                                    <AddressSuggestion form={form} viewAddress={value} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item className="d-none" name={"company_id"} label="Address">
                                    <Input placeholder="company_id" className="max_width" />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ position: "relative" }}>
                                <Form.Item name={"password"} label="Password">
                                    <Input.Password
                                        readOnly
                                        placeholder="Password"
                                        className="max_width"
                                    />
                                </Form.Item>
                                <div className="changePasswordbtnOverLap">
                                    <Button type="primary" onClick={handleShowForm}>
                                        Change Password
                                    </Button>
                                </div>
                            </Col>
                            {/* <Col
                                span={24}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}>
                                <Button type="primary" onClick={handleShowForm}>
                                    Change Password
                                </Button>
                            </Col> */}
                        </Row>
                    </Col>
                    <Col span={24} className="employee_view_btn">
                        {/* <div>
                            <Button type="primary" htmlType="submit">
                                Cancel
                            </Button>
                        </div> */}
                        <Form.Item>
                            <Button
                                loading={editLoading}
                                type="primary"
                                htmlType="submit"
                                className="saveButtonPrimary"
                                icon={<CheckOutlined />}>
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                    {visibleFields === true ? (
                        <ChangePassword
                            handleChangePassword={handleChangePassword}
                            loading={editPasswordLoading}
                        />
                    ) : null}
                </Row>
            </Form>
        </Spin>
    )
}

export default Profile
