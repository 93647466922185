import { createSlice } from "@reduxjs/toolkit"
import { message } from "antd"

const customSlice = createSlice({
    name: "customSlice",
    initialState: {
        employee: null,
        vendor : null,
        customer: null
    },
    reducers: {
        setEmployee: (state, action) => {
            state.employee = action.payload
        },
        setVendor: (state, action) => {
            state.vendor = action.payload
        },
        setCustomer: (state, action) => {
          state.customer = action.payload  
        },
        setError: () => {
            message.error("helo")
        }
    },
})

export const { setEmployee, setVendor, setCustomer } = customSlice.actions

export default customSlice.reducer
