import { Suspense, lazy } from "react";
import {  useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
// import ErrorPage from "./components/errorpage/ErrorPage";
import SuperAdminLogin from "./global/login/superAdminLogin/SuperAdminLogin";

import Dashboard from "./views/dashboard/Dashboard";
import Notifications from "./views/notifications/Notifications";
import Settings from "./views/settings/Settings";
import VendorList from "./views/vendors/VendorLists";
import ViewVendor from "./views/vendors/ViewVendor";
import ViewTimeSheet from "./views/timesheets/ViewTimeSheet";
import Loader from "./components/loader/Loader";
import ForgetPassword from "./global/login/adminLogin/ForgetPassword";
import ForgetPasswordEmail from "./global/login/adminLogin/ForgetPasswordEmail";
import EmpTimeSheet from "./views/timesheets/EmpTimeSheet";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Demo from "./components/demo";
import AdminMaster from "./views/master/AdminMaster";
import SuperAdminMaster from "./views/master/SuperAdminMaster";

const CheckIn = lazy(() => import("./components/check_id_new/CheckIn"));
const SuperAdminSettings = lazy(() => import("./views/settings/SuperAdminSettings"));
const AddEditCustomer = lazy(() => import("./views/customers/AddEditCustomer"));
const AdminLogin = lazy(() => import("./global/login/adminLogin/AdminLogin"));
const Layouts = lazy(() => import("./global/layout/Layout"));
const TimeSheets = lazy(() => import("./views/timesheets/TimeSheets"));
const Reports = lazy(() => import("./views/reports/Report"));
const CustomersList = lazy(() => import("./views/customers/CustomersList"));
const ViewCustomer = lazy(() => import("./views/customers/ViewCustomer"));
const EmployeeList = lazy(() => import("./views/employee/EmployeeList"));
const Payments = lazy(() => import("./views/payments/PaymentsList"));
const AddEditVendor = lazy(() => import("./views/vendors/AddEditVendor"));
const AddEditEmployee = lazy(() => import("./views/employee/AddEditEmployee"));
// const EditEmployee = lazy(() => import("./views/employee/EditEmployee"))
const ViewEmployee = lazy(() => import("./views/employee/ViewEmployee"));

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
	//states
	const role = useSelector((state) => state.auth.role);

	return (
		<Suspense fallback={<Loader router={true} />}>
			<Routes>
				<Route
					path="/"
					element={
						<Navigate
							to={
								// person === "admin" ?
								"/admin/login"
								//  : "/super-admin/login"
							}
						/>
					}
				/>
				{/* <Route
					path=""
					element={
						<Navigate
							to={
								// person === "admin" ?
								"/admin/login"
								//  : "/super-admin/login"
							}
						/>
					}
				/> */}
				<Route path="/super-admin/login" element={<SuperAdminLogin />} />
				<Route path="/super-admin/*" element={<Navigate to="/super-admin/login" />} />
				<Route path="/super-admin" element={<Navigate to="/super-admin/login" />} />
				<Route path="/admin/*" element={<Navigate to="/admin/login" />} />
				<Route path="/admin" element={<Navigate to="/admin/login" />} />
				<Route path="/admin/login" element={<AdminLogin />} />
				<Route path="/:companyId/check-in" element={<CheckIn />}></Route>
				<Route path="/check-in" element={<Demo />}></Route>
				<Route path="/auth/forget-password" element={<ForgetPassword />} />
				<Route path="/auth/reset-password/*" element={<ForgetPasswordEmail />} />
				{role !== "SuperAdmin" ? (
					<>
						{/* <Route path="/" element={<Navigate to="/admin/login" />} /> */}
						<Route path="/admin" element={<Layouts />}>
							<Route path="/admin/dashboard" element={<Dashboard />} />
							<Route path="/admin/employees" element={<EmployeeList />} />
							<Route path="/admin/employees/add" element={<AddEditEmployee />} />
							<Route
								path="/admin/employees/edit/:employeeId"
								element={<AddEditEmployee />}
							/>
							<Route
								path="/admin/employees/view/:employeeId"
								element={<ViewEmployee />}
							/>
							<Route path="/admin/timesheets" element={<TimeSheets />} />
							<Route
								path="/admin/timesheets/:employeeId"
								element={<ViewTimeSheet />}
							/>
							<Route
								path="/admin/emp-timesheet/:employeeId"
								element={<EmpTimeSheet />}
							/>
							<Route path="/admin/vendors" element={<VendorList />} />
							<Route path="/admin/vendors/add" element={<AddEditVendor />} />
							<Route
								path="/admin/vendors/edit/:vendorId"
								element={<AddEditVendor />}
							/>
							<Route path="/admin/vendors/view/:vendorId" element={<ViewVendor />} />
							<Route path="/admin/reports" element={<Reports />} />
							<Route path="/admin/admin-master" element={<AdminMaster />} />
							<Route path="/admin/notifications" element={<Notifications />} />
							<Route path="/admin/settings" element={<Settings />} />
							{/* <Route path="/admin/*" element={<ErrorPage />} /> */}
						</Route>
					</>
				) : (
					<>
						<Route path="/" element={<Layouts />}>
							<Route path="/super-admin/dashboard" element={<Dashboard />} />
							<Route path="/super-admin/customers" element={<CustomersList />} />
							<Route path="/super-admin/customer/add" element={<AddEditCustomer />} />
							<Route
								path="/super-admin/customer/edit/:customerId"
								element={<AddEditCustomer />}
							/>
							<Route
								path="/super-admin/customer/view/:customerId"
								element={<ViewCustomer />}
							/>
							<Route path="/super-admin/payment-methods" element={<Payments />} />
							<Route path="/admin/superadmin-master" element={<SuperAdminMaster />} />
							<Route path="/super-admin/settings" element={<SuperAdminSettings />} />

							{/* <Route path="/super-admin/reports" element={<EmployeeList />} />
                            <Route path="/super-admin/notifications" element={<Notifications />} />
                            <Route path="/super-admin/settings" element={<Settings />} /> */}
							{/* <Route path="/*" element={<ErrorPage />} /> */}
						</Route>
					</>
				)}
			</Routes>
		</Suspense>
	);
}

export default App;
