import React, { useEffect, useState } from "react"
import { Button, Typography, Avatar, Row, Col } from "antd"
import BreadCrumbs from "../breadcrumb/BreadCrumbs"
import { AiOutlinePlus } from "react-icons/ai"
import { CiUser } from "react-icons/ci/"
import "./main.css"
import Banner from "../../assets/dashboard_banner.png"

const { Title } = Typography;

// import {} from ""

const MainLayout = ({ title, children, button, breadcrumbs, upload, image, banner }) => {

    const [src, setSrc] = useState('')

    const customTheme = {
        typography: {
            fontFamily: 'Avenir, sans-serif',
        },
    };

    useEffect(() => {
        if (image) {
            setSrc(image)
        }
    }, [image])

    return (
        <div className="layout_content">
            <div>
                <div className="layout_top_left">
                    <div className="layout_top_left_title">
                        {/* <Typography.Title level={2}>{title}</Typography.Title> */}
                        <Typography {...customTheme}>
                            <Title style={{ fontSize: "35px", fontWeight: "bold" }}>{title}</Title>
                        </Typography>
                        {button.length >= 1 ? (
                            <Button
                                icon={<AiOutlinePlus className="icon_btn_add" />}
                                className="layout_add_btn"
                                type="default"
                                onClick={button[0].func}>
                                {button[0].label}
                            </Button>
                        ) : null}
                    </div>
                    <BreadCrumbs breadcrumbs={breadcrumbs} />
                    {banner &&
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <Row style={{
                                background: '#E3ECF6',
                                padding: '25px',
                                borderRadius: '15px',
                                position: 'relative',
                            }}>
                                <Col span={15} style={{ color: '#5E7997', fontSize: '14px', lineHeight: '20px' }}>
                                    Managing the exact time worked by your employees comes hand in hand with the payroll. You can’t have one without the other,
                                    you must use a timekeeping method and retain records for your employees
                                </Col>
                                <Col span={9} style={{ position: 'absolute', right: '5%', top: '-100%' }}>
                                    <img src={Banner} alt=""
                                        style={{
                                            width: '450px',
                                            height: '180px'
                                        }} />
                                </Col>
                            </Row>
                        </Col>
                    }
                </div>
                <div className="layout_top_right" onClick={() => upload.length >= 1 && upload[0]?.func()}>
                    {upload?.length >= 1 ? (
                        <>
                            <Typography.Text>{upload[0]?.label}</Typography.Text>
                            <Avatar icon={<CiUser />} src={src} className="layout_top_right_avatar" />
                        </>
                    ) : null}
                </div>
            </div>
            <div className="layout_children">{children}</div>
        </div>
    )
}

export default MainLayout
