import { Spin } from "antd"
import { isUndefined } from "lodash"
import React from "react"

const Loader = ({ loading, router }) => {
    return (
        <div className="loader_wrapper" style={{
            width: router ? "100vw" : "",
            height: router ? "100vh" : "",
        }}>
            <Spin spinning={loading ?? false}>
            </Spin>
        </div >
    )
}

export default Loader
