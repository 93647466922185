import React, { useEffect, useState, useRef } from "react";
import { Checkbox, DatePicker, Input } from "antd";
import { Table } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import MainLayout from "../../components/main_layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTimesheetListQuery } from "../../features/api/adminApi/timesheet_api";
import dayjs from "dayjs";
import "./timesheet.css";

const ViewTimeSheet = () => {
	//Hooks
	const searchRef = useRef();
	const { employeeId } = useParams();
	//States
	const [open, setOpen] = useState(false);
	const [filterOptions, setFilterOption] = useState(false);
	const [timeSheetData, setTimeSheetData] = useState([]);
	const [defaultDate, setDefaultData] = useState(() => {
		let date = [dayjs(), dayjs()];
		if (dayjs().get("date") <= 15) {
			date[0] = dayjs(date[0].set("date", 1));
			date[1] = dayjs(date[1].set("date", 15));
		} else {
			date[0] = dayjs(date[0].set("date", 15));
			date[1] = dayjs(date[1].set("date", dayjs().daysInMonth()));
		}
		return date;
		// return []
	});
	const [filteredData, setFilteredData] = useState([]);

	const [filters, setFilters] = useState([
		{
			label: "Emp. ID",
			value: "id",
		},
		{
			label: "Check-In ID",
			value: "check_in_id",
		},
		{
			label: "Employee Name",
			value: "employee_name",
		},
		{
			label: "Email",
			value: "email",
		},
		{
			label: "Phone",
			value: "phone",
		},
		{
			label: "Role",
			value: "role",
		},
		{
			label: "Department",
			value: "department",
		},
		{
			label: "Status",
			value: "status",
		},
	]);

	//Api
	const { data: timeSheetApi, isFetching: tableFetching } = useTimesheetListQuery();

	const options = [
		{
			label: "Emp. ID",
			value: "id",
		},
		{
			label: "Check-In ID",
			value: "check_in_id",
		},
		{
			label: "Employee Name",
			value: "employee_name",
		},
		{
			label: "Email",
			value: "email",
		},
		{
			label: "Phone",
			value: "phone",
		},
		{
			label: "Role",
			value: "role",
		},
		{
			label: "Department",
			value: "department",
		},
		{
			label: "Status",
			value: "status",
		},
	];
	const showModal = () => {
		setOpen(true);
	};

	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			render: (_, record) => dayjs(record.date).format("YYYY-MM-DD"),
			sorter: (a, b) => dayjs(a.date).get("date") - dayjs(b.date).get("date"),
		},
		{
			title: "Checkin ID",
			dataIndex: "employee_id",
			sorter: (a, b) => a.employee_id - b.employee_id,
		},
		{
			title: "Employee  Name",
			dataIndex: "employee_name",
			sorter: (a, b) => a.first_name - b.first_name,
		},
		{
			title: "Status",
			dataIndex: "status",
			sorter: (a, b) => a.status - b.status,
		},
		{
			title: "Checkin Status",
			dataIndex: "check_in_status",
		},
		{
			title: "Total Hours",
			dataIndex: "total_hours",

			render: (_, record) => `heo`,
			// render: (_, record) => `${record.total_hours}`,
		},
		{
			title: "Attendance",
			children: [
				{
					title: "Early Check-in",
					className: "early",
					dataIndex: "early_check_id",
					key: "early_check_id",
					width: 150,
					render: (_, record) => {
						return 3;
					},
				},
				{
					title: "Late Check-in",
					className: "late",
					dataIndex: "late_check_in",
					key: "late_check_ii",
					width: 150,
					render: (_, record) => {
						return 3;
					},
				},
				{
					title: "Off",
					className: "off",
					dataIndex: "off",
					key: "off",
					width: 150,
					render: (_, record) => {
						return 3;
					},
				},
			],
		},
	];
	const breadcrumbs = [
		{
			title: "Dashboard",
			href: "/admin/dashboard",
		},
		{
			title: "Timesheet",
			href: "/admin/timesheet",
		},
	];

	const handleGlobalSearch = (e) => {
		const filter = timeSheetData.filter((record) => {
			return (
				String(record["employee_id"])
					.toLowerCase()
					.includes(String(e.target.value).toLowerCase()) ||
				String(record["check_in_id"])
					.toLowerCase()
					.includes(String(e.target.value).toLowerCase()) ||
				String(record["first_name"])
					.toLowerCase()
					.includes(String(e.target.value).toLowerCase()) ||
				String(record["last_name"])
					.toLowerCase()
					.includes(String(e.target.value).toLowerCase())
			);
		});
		setFilteredData(filter);
	};
	const filterChange = (checkedValues) => {
		console.log("checked = ", checkedValues);
	};

	//useEffect
	//Employe List
	useEffect(() => {
		if (timeSheetApi !== undefined) {
			setTimeSheetData(timeSheetApi?.data?.data);
		}
	}, [timeSheetApi]);

	return (
		<>
			{/* Layout */}
			<MainLayout
				title={"Timesheet"}
				breadcrumbs={breadcrumbs}
				upload={[]}
				button={[]}
				banner={true}>
				<div>
					{/* Top */}
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "15px",
							padding: "8px",
							justifyContent: "center",
							background: "#ededed",
							borderRadius: "15px 15px 0 0",
							width: "600px",
						}}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "10px",
								paddingInline: 10,
								width: "100%",
							}}>
							<Input
								placeholder="Search Employees"
								onChange={(e) => handleGlobalSearch(e)}
								ref={searchRef}
								style={{
									width: "100%",
								}}
							/>
							<DatePicker.RangePicker
								style={{
									width: "100%",
								}}
								defaultValue={defaultDate}
							/>
							<FilterOutlined
								onClick={() => {
									setFilterOption(!filterOptions);
								}}
								style={{ fontSize: "26px", cursor: "pointer" }}
							/>
						</div>
					</div>
					{filterOptions && (
						<div
							className="table_check_box"
							style={{
								// width: "100%",
								display: "flex",
								background: "#ededed",
								padding: "10px",
								gap: "25px",
							}}>
							<Checkbox.Group
								options={options}
								onChange={filterChange}
								defaultValue={[
									"id",
									"check-in_id",
									"employee_name",
									"email",
									"phone",
									"role",
									"department",
									"status",
								]}
							/>
						</div>
					)}
					<div
						style={{
							height: "100%",
							width: "100%",
						}}>
						<Table
							size="small"
							rowKey={(record) => record?.id}
							loading={tableFetching}
							columns={columns}
							dataSource={
								searchRef.current?.input.value.length > 0
									? filteredData
									: timeSheetData
							}
							pagination={{
								pageSize: 10,
								pageSizeOptions: [10, 25, 50, 100.5],
								hideOnSinglePage: true,
							}}
							scroll={{
								x: 700,
								y: 400,
							}}
						/>
					</div>
				</div>
			</MainLayout>
		</>
	);
};

export default ViewTimeSheet;
