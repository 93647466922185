import { createSlice } from "@reduxjs/toolkit"

const customerSlice = createSlice({
    name: "customerSlice",
    initialState: {
         customer: null
    },
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload
        }
    },
})

export const { setCustomer } = customerSlice.actions

export default customerSlice.reducer
