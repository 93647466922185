import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input, message, Select } from "antd";
import { Table } from "antd";
import {
	EllipsisOutlined,
	EyeOutlined,
	DeleteOutlined,
	FormOutlined,
	FilterOutlined,
} from "@ant-design/icons";
import MainLayout from "../../components/main_layout/MainLayout";
import { Link, useNavigate } from "react-router-dom";
import {
	useDeleteVendorMutation,
	useListVendorQuery,
	useVendorStatusChangeMutation,
} from "../../features/api/adminApi/vendor_api";
import CustomerModal from "../../components/custommodal/CustomerModal";
import errorMessage from "../../global/errorMessage/ErrorMessage";
import { makeQueryString } from "../../global/helpers"
const { Search } = Input;

const VendorList = () => {
	//Hooks
	const navigate = useNavigate();

	//state
	const [filterOptions, setFilterOption] = useState(false);
	const [vendorData, setVendorData] = useState([]);
	const [columnData, setColumnData] = useState([]);
	const [deleteId, setDeleteId] = useState("");

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [total , setTotal] = useState(0)
	const [searchParams, setSearchParams] = useState({
		search: "",
		per_page: 10,
		page: 1,
	});
	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
	};

	//Delete Vendor API
	const [deleteVendor, { isLoading: deleteLoading }] = useDeleteVendorMutation();
	//Vendor Status Change API
	const [statusChange, { isLoading: statusLoading }] = useVendorStatusChangeMutation();

	//vendor list Api
	const {
		data: vendorapi,
		isFetching: newFilterFetching,
		refetch,
	} = useListVendorQuery(makeQueryString(searchParams));

	const breadcrumbs = [
		{
			title: "Dasboard",
			href: "/admin/dashboard",
		},
		{
			title: "Our Vendors",
			href: "/admin/vendors",
		},
	];

	const options = [
		{
			label: "Ven ID",
			value: "reference_no",
		},
		{
			label: "Company Name",
			value: "business_name",
		},
		{
			label: "Contact Name",
			value: "first_name",
		},
		{
			label: "Email",
			value: "email",
		},
		{
			label: "Phone No",
			value: "phone_number",
		},
		{
			label: "Type",
			value: "type",
		},
		{
			label: "Status",
			value: "status",
		},
	];

	const handleStatus = (value, id) => {
		const params = {
			formData: {
				status: value,
			},
			id: id,
		};
		statusChange(params)
			.unwrap()
			.then((res) => {
				message.success("Success");
			})
			.catch((err) => errorMessage(err?.data?.error));
	};

	const handleFinish = (id) => {
		deleteVendor(id)
			.unwrap()
			.then((res) => {
				message.success(res?.message ?? "Vendor deleted successfully");
				setIsModalOpen(false);
			})
			.catch((err) => errorMessage(err?.data?.error));
	};

	const columns =  [
		{
			title: "Ven.ID",
			dataIndex: "reference_no",
			width: "90px",
			sorter: {
				compare: (a, b) => a.id - b.id,
			},
			render: (_, record) => (
				<Link to={`/admin/vendors/view/${record.id}`}>{record?.reference_no}</Link>
			),
		},
		{
			title: "Company Name",
			dataIndex: "business_name",
			width: "200px",
			sorter: (a, b) => a.business_name.localeCompare(b.business_name),
		},
		{
			title: "Contact Name",
			dataIndex: "first_name",
			width: "250px",
			render: (_, record) => {
				return `${record?.first_name} ${record?.last_name}`;
			},
			// render: (_, record) => <Link to={`/admin/vendors/view/${record.id}`}>{record?.business_name}</Link>,
		},
		{
			title: "Email",
			dataIndex: "email",
			width: "100%",
		},
		{
			title: "Phone No",
			dataIndex: "phone_number",
			width: "100%",
		},
		{
			title: "Type",
			dataIndex: "business_type_name",
			width: "100%",
		},
		{
			title: "Status",
			dataIndex: "status",
			align: "center",
			width: "100%",
			render: (_, record) => {
				return (
					<Select
						style={{
							width: "120px",
						}}
						onChange={(e) => {
							handleStatus(e, record.id);
						}}
						defaultValue={record?.status}
						options={[
							{
								label: "Active",
								value: 1,
							},
							{
								label: "Inactive",
								value: 0,
							},
						]}
					/>
				);
			},
		},
		{
			title: "Action",
			align: "center",
			width: "100%",
			render: (_, record) => {
				const items = [
					{
						label: "View",
						key: "1",
						icon: <EyeOutlined className="actions_icons" />,
						onClick: () => {
							// dispatch(setVendor(record))
							navigate(`/admin/vendors/view/${record?.id}`);
						},
					},
					{
						label: "Edit",
						key: "2",
						icon: <FormOutlined className="actions_icons" />,
						onClick: () => {
							// dispatch(setVendor(record))
							navigate(`/admin/vendors/edit/${record?.id}`);
						},
					},
					{
						label: "Delete",
						key: "3",
						icon: <DeleteOutlined className="actions_icons" />,
						onClick: () => {
							setDeleteId(record?.id);
							setIsModalOpen(true);
						},
					},
				];
				return (
					<Dropdown
						className="main-modal"
						placement="bottom"
						menu={{
							items,
						}}>
						<EllipsisOutlined
							style={{
								fontSize: "20px",
								cursor: "pointer",
							}}
							key="ellipsis"
						/>
					</Dropdown>
				);
			},
		},
	];

	const filterChange = (checkedValues) => {
		// console.log(checkedValues, 'checkedValues')

		//Add action into array
		let checkNewValues = [...checkedValues, "action"];

		//Convert array value to array of object
		const filterData = checkNewValues.map((val) => ({ dataIndex: val }));

		// console.log(filterData, "filterData")
		//Compare check values and columns value
		let filterArr = columns.filter((col1) => {
			return filterData.some(
				(col2) => col1.dataIndex === col2.dataIndex || col1.title === "Action"
			);
		});
		// console.log(filterArr, "final")
		setColumnData(filterArr);
	};

	//useEffect
	useEffect(() => {
		if (vendorapi !== undefined) {
			const  { data , total} = vendorapi?.data
			setVendorData(data);
			setTotal(total)
		}
	}, [vendorapi]);

	useEffect(() => {
		setColumnData(columns);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (filterOptions) {
			setColumnData(columns);
		}
	}, [filterOptions]);


	useEffect(() => {
		window.addEventListener("resize", setDimension);

		return () => {
			window.removeEventListener("resize", setDimension);
		};
	}, [screenSize]);

	useEffect(() => {
		refetch();
	}, [searchParams, refetch]);

	return (
		<>
			{/* Layout */}
			<MainLayout
				title={"Our Vendors"}
				breadcrumbs={breadcrumbs}
				upload={[]}
				button={[
					{
						label: "Add Vendor",
						func: () => {
							navigate("/admin/vendors/add");
						},
					},
				]}
				banner={screenSize.dynamicWidth >= 1200 ? true : false}>
				<div>
					<section className="outlet_top">
						<section className="outlet_top_search_wrapper">
							<Search
								placeholder="Search Vendors"
								loading={newFilterFetching}
								onSearch={(value) => {
									const setting = {
										...searchParams,
										search : value
									}
									setSearchParams(setting)
								}}
								onChange={(event) => {
									const setting = {
										...searchParams,
										search : event.target.value
									}
									setSearchParams(setting)
								}}
								style={{
									width: 250,
								}}
							/>
							<FilterOutlined
								onClick={() => {
									setFilterOption(!filterOptions);
								}}
								style={{ fontSize: "26px", cursor: "pointer" }}
							/>
						</section>
					</section>
					{filterOptions && (
						<div
							className="table_check_box"
							style={{
								display: "flex",
								background: "#ededed",
								padding: "10px",
								// gap: "25px",
								overflowX: "scroll",
							}}>
							<Checkbox.Group
								options={options}
								onChange={filterChange}
								defaultValue={[
									"reference_no",
									"business_name",
									"first_name",
									"contact_name",
									"email",
									"phone_number",
									"type",
									"status",
								]}
							/>
						</div>
					)}
					<div className="outlet_table">
						<Table
							size="small"
							columns={columnData}
							loading={newFilterFetching === true || statusLoading === true ? true : false}
							dataSource={vendorData}
							pagination={{
								total: total,
								defaultPageSize: 10,
								pageSizeOptions: [10, 25, 50, 100, 500],
								showSizeChanger: true,
								onChange: (page, per_page) => {
									const setting = {
										...searchParams,
										page,
										per_page
									}
									setSearchParams(setting)
								},
							}}
							scroll={{
								x: 1300,
								y: 400,
							}}
						/>
					</div>
				</div>
				{isModalOpen ? (
					<CustomerModal
						handleFinish={() => handleFinish(deleteId)}
						title={"Delete Vendor"}
						setIsModalOpen={setIsModalOpen}
						isModalOpen={isModalOpen}
						loading={deleteLoading}>
						Are you sure to delete?
					</CustomerModal>
				) : null}
			</MainLayout>
		</>
	);
};

export default VendorList;
