import { CopyOutlined } from "@ant-design/icons";
import { Col, Divider, message, Row, Typography } from "antd";
import { useRef } from "react";

const CustomerCheckInformation = ({ customerData }) => {
    const textRef = useRef(null)

    const handleClipBoard = () => {
        setTimeout(() => {
            if (textRef.current) {
                let value = textRef.current.textContent
                // Copy the value to the clipboard
                navigator.clipboard.writeText(value);
                message.success("Copied")
            }
        }, 0);
    }
    return (
        <>
            <Typography.Title level={4} style={{ paddingTop: '20px' }}>Check-In Information</Typography.Title>
            <Divider />
            <Row>
                <Col style={{ display: "flex", gap: '15px', paddingTop: '50px', fontSize: "15px" }}>
                    <p style={{ paddingLeft: '5px' }}>Check_in Url:</p>
                    <p ref={textRef}>{`${process.env.REACT_APP_DOMAIN_URL}/${customerData?.url_parameter}/check-in`}</p>
                    <CopyOutlined style={{ cursor: "pointer", fontSize: "25px" }} onClick={handleClipBoard} />
                </Col>
            </Row>
        </>
    )
}

export default CustomerCheckInformation