import { Input } from 'antd';
import React from 'react';

const ResultComponent = ({ result, onChangeFn }) => {
    return (
        <div className="result">
            <Input value={result} onChange={(e) => onChangeFn(e)} />
        </div>

    );
};

export default ResultComponent;